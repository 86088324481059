import React, {
  useParams,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
import {} from "react-router-dom";
import { Row, Col, Form, Input, Button, notification, Modal, Tabs } from "antd";
import { Element, scroller } from "react-scroll";

import { Auth } from "../../utils/AuthContext";
import Firebase from "../../utils/firebaseConfig";

import "../../assets/css/sala.css";
import logoS from "../../assets/images/sitio/inicio/logo_pnsv_zonachat.png";
import imagen_proximamente from "../../assets/images/sitio/inicio/bienvenida.jpg";

import PopUpPregunta from "./PopUpPregunta";

import rede_facebook from "../../assets/images/temp/50x50.png";
import rede_ln from "../../assets/images/temp/50x50.png";
import rede_youtube from "../../assets/images/temp/50x50.png";

import like_emoji from "../../assets/images/premio_2022/auditorio/pnsv_web_ReaccionLike.png";
import corazon_emoji from "../../assets/images/premio_2022/auditorio/pnsv_web_ReaccionLove.png";
import risa_emoji from "../../assets/images/premio_2022/auditorio/pnsv_web_ReaccionSonrisa.png";
import sorpresa_emoji from "../../assets/images/premio_2022/auditorio/pnsv_web_ReaccionAsombra.png";

const { TextArea } = Input;

const app = new Firebase();

const mensaje = (tipo, titulo, texto) => {
  notification[tipo]({
    message: titulo,
    description: texto,
  });
};
const layout = {
  wrapperCol: {
    span: 24,
  },
};

const Live = (props) => {
  const [mis_preguntas, misPreguntas] = useState(false);
  const [en_vivo, enVivo] = useState(true);
  const [preguntas_contestadas, preguntasContestadas] = useState(false);

  const [modal_cuestionario, setModalCuestionario] = useState(false);
  const [num_pregunta, setNumPregunta] = useState(0);
  const {
    url,
    comentarios,
    webinar,
    urlfb,
    urltw,
    urlyt,
    urlln,
    comentario_empresa,
    nombre_video,
    datos,
  } = props;
  const [preguntas_popup_usuario, setPreguntasPopUpUsuario] = useState({});
  const [autoplay, setAutoPlay] = useState(url);
  const [emoji, setEmoji] = useState(0);
  const [emoji_like, setEmojiLike] = useState(false);
  const [emoji_corazon, setEmojiCorazon] = useState(false);
  const [emoji_risa, setEmojiRisa] = useState(false);
  const [emoji_sorpresa, setEmojiSorpresa] = useState(false);

  const { usuario } = useContext(Auth);

  const ref_form_pregunta = useRef();
  const ref_div_comentarios = useRef();
  const ref_div_comentarios_movil = useRef();

  console.log(comentarios);

  useEffect(() => {
    if (usuario) {
      app.obtener_objeto(
        webinar + "/respuestas-usuarios-en-vivo",
        "telefono",
        usuario.phoneNumber,
        (data) => {
          console.log("me trajo");
          console.log(data);
          setPreguntasPopUpUsuario(data);
        }
      );
    }
  }, [usuario]);

  useEffect(() => {
    if (Object.keys(preguntas_popup_usuario).length !== 0) {
      app.obtener_objetos(webinar + "/configuracion", true, (data) => {
        setNumPregunta(data[0].pregunta_popup);

        if (data[0].pregunta_popup === 0) {
          setModalCuestionario(false);
        } else {
          if (
            preguntas_popup_usuario["respuesta" + data[0].pregunta_popup] ===
              undefined ||
            preguntas_popup_usuario["respuesta" + data[0].pregunta_popup] ===
              null
          ) {
            setModalCuestionario(true);
          } else {
            setModalCuestionario(false);
          }
        }
      });
    }
  }, [preguntas_popup_usuario]);

  const onResponseCuestionario = (valor_respuesta) => {
    setModalCuestionario(false);

    preguntas_popup_usuario["respuesta" + num_pregunta] = valor_respuesta;

    app.escribir(
      webinar + "/respuestas-usuarios-en-vivo",
      preguntas_popup_usuario.key,
      preguntas_popup_usuario,
      (item) => {
        mensaje("success", "Gracias por tu participación", "");
      }
    );
  };

  useEffect(() => {
    setAutoPlay(url);
  }, []);

  let visualizar_preguntas = comentarios
    .filter((pre) => {
      if (pre.status === "aceptada") {
        return true;
      } else {
        return false;
      }
    })
    .map((pre, index) => {
      return (
        <>
        <img
              src={
                "https://ui-avatars.com/api/?background=random&name=" +
                pre.nombre
              }
              style={{
                width: "30px",
                borderRadius: "100%",
                position: "absolute",
                left: '5px',
                display:'none'
              }}
            />
        <div className="balloon">
          <p className="texto-preguntas">
            
            <span className="text-pre">{pre.pregunta}</span>
            <br />
            <strong>
              <span className="text-nom">{pre.nombre}</span>
            </strong>
            <br />
            <strong>
              <span className="text-empre">{pre.organizacion}</span>
            </strong>
          </p>
        </div>
        </>
      );
    });

  const onFinish = (values) => {
    let pregunta = {
      nombre: datos.nombre,
      pregunta: values.pregunta,
      status: "aceptada",
      empresa: comentario_empresa,
      nombre_video: nombre_video,
    };

    app.escribir(
      webinar + "/preguntas-publico/chat",
      undefined,
      pregunta,
      () => {
        mensaje("success", "Tu comentario ha sido enviado");
        ref_form_pregunta.current.resetFields();
      }
    );
  };

  useEffect(() => {
    ref_div_comentarios.current.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
    //ref_div_comentarios_movil.current.scrollIntoView({block: "nearest", behavior: "smooth"});
  }, [,]);

  return (
    <Row
      align="top"
      className={
        window.location.pathname === "/felicitacion-conductor"
          ? ""
          : "fondo_live"
      }
    >
      <Col span={24}>
        <br />
      </Col>

      <Col
        xs={{ span: 20, offset: 2 }}
        sm={{ span: 20, offset: 2 }}
        md={{ span: 20, offset: 2 }}
        lg={{ span: 13, offset: 3 }}
        style={{ textAlign: "center" }}
      >
        {url !== null ? (
          <>
            <div className="contenedor_video">
              <iframe
                className="video"
                src={autoplay}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; allowfullscreen;"
                allowfullscreen="1"
              ></iframe>
            </div>
          </>
        ) : (
          <>
            <img
              className="imagen_logo"
              src={imagen_proximamente}
              style={{ width: "100%" }}
            />
          </>
        )}
      </Col>
      <Col
        xs={{ span: 20, offset: 2 }}
        sm={{ span: 20, offset: 2 }}
        md={{ span: 20, offset: 2 }}
        lg={{ span: 5, offset: 0 }}
        className="contenedor_live_preguntas"
      >
        <Row justify="center">
          <Col xs={{ span: 24 }} lg={{ span: 0 }}>
            <br />
          </Col>

          <Col xs={0} md={{ span: 24, offset: 0 }} lg={{ span: 23, offset: 1 }}>
            <Row style={{ display: "none" }}>
              <Col
                span={8}
                className={
                  mis_preguntas === true
                    ? "tipo_preguntas_select"
                    : "tipo_preguntas"
                }
              >
                <p
                  style={{ marginBottom: "0px" }}
                  onClick={() => (
                    misPreguntas(true),
                    enVivo(false),
                    preguntasContestadas(false)
                  )}
                >
                  Mis preguntas
                </p>
              </Col>
              <Col
                span={8}
                className={
                  en_vivo === true ? "tipo_preguntas_select" : "tipo_preguntas"
                }
              >
                <p
                  style={{ marginBottom: "0px" }}
                  onClick={() => (
                    enVivo(true),
                    misPreguntas(false),
                    preguntasContestadas(false)
                  )}
                >
                  En vivo
                </p>
              </Col>
              <Col
                span={8}
                className={
                  preguntas_contestadas === true
                    ? "tipo_preguntas_select"
                    : "tipo_preguntas"
                }
              >
                <p
                  style={{ marginBottom: "0px" }}
                  onClick={() => (
                    enVivo(false),
                    misPreguntas(false),
                    preguntasContestadas(true)
                  )}
                >
                  Contestadas
                </p>
              </Col>
            </Row>
          </Col>

          <Col xs={0} md={{ span: 24, offset: 0 }} lg={{ span: 23, offset: 1 }}>
            <div className="border-preguntas">
              <div className="ver-preguntas">
                <Row>
                  <Col span={24}>
                    {en_vivo && (
                      <div>
                        {visualizar_preguntas}
                        <div>
                          <div ref={ref_div_comentarios}>&nbsp;</div>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} md={0}>
            <Row>
              <Col span={24} className="tipo_preguntas_select">
                <p style={{ marginBottom: "0px" }}>En vivo</p>
              </Col>
              <Col span={24} className="ver-preguntas">
                <div>
                  {visualizar_preguntas}
                  <div>
                    <div ref={ref_div_comentarios_movil}>&nbsp;</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            xs={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 23, offset: 1 }}
            className="contenedor_enviar"
          >
            <Form
              {...layout}
              name="basic"
              initialValues={{
                pregunta: "",
                remember: true,
              }}
              onFinish={onFinish}
              ref={ref_form_pregunta}
            >
              <Row>
                <Col span={22} offset={1}>
                  <div className="border-preguntas">
                    <Form.Item
                      name="pregunta"
                      rules={[
                        {
                          required: true,
                          message: "Por favor escribe una pregunta",
                        },
                      ]}
                    >
                      <TextArea className="text_enviar" disabled={false} />
                    </Form.Item>
                  </div>
                </Col>
                <Col
                  xs={{ span: 2, offset: 1 }}
                  md={{ span: 2, offset: 1 }}
                  style={{ textAlign: "left" }}
                >
                  <img
                    src={like_emoji}
                    className="icono_emoji"
                    onClick={() => {
                      setEmojiLike(true);
                    }}
                  />
                  <span className="contenedor_animacion">
                    {emoji_like && (
                      <img
                        src={like_emoji}
                        className="animacion_emoji"
                        style={{ left: "-30px", bottom: "20px" }}
                      />
                    )}
                  </span>
                </Col>
                <Col
                  xs={{ span: 2, offset: 1 }}
                  md={{ span: 2, offset: 1 }}
                  style={{ textAlign: "left" }}
                >
                  <img
                    src={corazon_emoji}
                    className="icono_emoji"
                    onClick={() => {
                      setEmojiCorazon(true);
                    }}
                  />
                  <span className="contenedor_animacion">
                    {emoji_corazon && (
                      <img
                        src={corazon_emoji}
                        className="animacion_emoji"
                        style={{ left: "-30px", bottom: "20px" }}
                      />
                    )}
                  </span>
                </Col>
                <Col
                  xs={{ span: 2, offset: 1 }}
                  md={{ span: 2, offset: 1 }}
                  style={{ textAlign: "left" }}
                >
                  <img
                    src={risa_emoji}
                    className="icono_emoji"
                    onClick={() => {
                      setEmojiRisa(true);
                    }}
                  />
                  <span className="contenedor_animacion">
                    {emoji_risa && (
                      <img
                        src={risa_emoji}
                        className="animacion_emoji"
                        style={{ left: "-30px", bottom: "20px" }}
                      />
                    )}
                  </span>
                </Col>
                <Col
                  xs={{ span: 2, offset: 1 }}
                  md={{ span: 2, offset: 1 }}
                  style={{ textAlign: "left" }}
                >
                  <img
                    src={sorpresa_emoji}
                    className="icono_emoji"
                    onClick={() => {
                      setEmojiSorpresa(true);
                    }}
                  />
                  <span className="contenedor_animacion">
                    {emoji_sorpresa && (
                      <img
                        src={sorpresa_emoji}
                        className="animacion_emoji"
                        style={{ left: "-30px", bottom: "20px" }}
                      />
                    )}
                  </span>
                </Col>

                <Col
                  xs={{ span: 7, offset: 5 }}
                  lg={{ span: 7, offset: 2 }}
                  style={{
                    textAlign: "center",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <Form.Item>
                    <Button
                      type=""
                      htmlType="submit"
                      className="btn-enviar color_azul"
                      disabled={usuario ? false : true}
                    >
                      Enviar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <br />
        <br />
        <br />
      </Col>

      <Modal
        title={
          <span
            style={{ textAlign: "center", fontWeight: "bold" }}
            className="color_azul"
          >
            PARTICIPA EN NUESTRA ENCUESTA DE SATISFACCIÓN
          </span>
        }
        visible={modal_cuestionario}
        footer={null}
        onCancel={() => setModalCuestionario(false)}
      >
        <PopUpPregunta
          num_pregunta={num_pregunta}
          onResponse={onResponseCuestionario}
        />
      </Modal>
    </Row>
  );
};

export default Live;
