import React, { useState, useContext, useEffect, useRef } from "react";
import { Row, Col, Menu, Select } from "antd";
import "../../assets/css/expo.css";
import Firebase from "../../utils/firebaseConfig";
import { Auth } from "../../utils/AuthContext";
import { Redirect } from "react-router-dom";

import fondo_expo from "../../assets/images/premio_2022/expo/zonaexpo_pnsv2022.jpg";
import conin_inicial from "../../assets/images/premio_2021/Estado05.gif";
import conin_hablar from "../../assets/images/premio_2021/Estado03.gif";
import audio_expo from "../../assets/images/premio_2022/conin_audios/7_Dinamica_exposicion_y_actividades.mp3";

import miniatura1 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_acuityBrands.jpg";
import miniatura2 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_prysmian.jpg";
import miniatura3 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_airis.jpg";
import miniatura4 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_ANCE.jpg";
import miniatura5 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_arcosa.jpg";
import miniatura6 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_caname.jpg";
import miniatura7 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_casa segura.jpg";
import miniatura8 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_condumex.jpg";
import miniatura9 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_directorios.jpg";
import miniatura10 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_hitachi.jpg";
import miniatura11 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_humbrall.jpg";
import miniatura12 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_mabe.jpg";
import miniatura13 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_MT.jpg";
import miniatura14 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_petroleo.jpg";
import miniatura15 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_prolec.jpg";
import miniatura16 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_revistacaname.jpg";
import miniatura17 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_schneider.jpg";
import miniatura18 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_serintra.jpg";
import miniatura19 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_siemens energy.jpg";
import miniatura20 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_siemens.jpg";
import miniatura21 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_signify.jpg";
import miniatura22 from "../../assets/images/evento/expo/Zoom_stands/standsExpo_viakon.jpg";

import img1 from "../../assets/images/evento/expo/img1.jpg";
import img2 from "../../assets/images/evento/expo/img2.jpg";
import img3 from "../../assets/images/evento/expo/img3.jpg";
import img4 from "../../assets/images/evento/expo/img4.jpg";
import img5 from "../../assets/images/evento/expo/img5.jpg";
import img6 from "../../assets/images/evento/expo/img6.jpg";
import img7 from "../../assets/images/evento/expo/img7.jpg";
import img8 from "../../assets/images/evento/expo/img8.jpg";
import img9 from "../../assets/images/evento/expo/img9.jpg";
import img10 from "../../assets/images/evento/expo/img10.jpg";
import img11 from "../../assets/images/evento/expo/img11.jpg";
import img12 from "../../assets/images/evento/expo/img12.jpg";
import img13 from "../../assets/images/evento/expo/img13.jpg";
import img14 from "../../assets/images/evento/expo/img14.jpg";
import img15 from "../../assets/images/evento/expo/img15.jpg";
import img16 from "../../assets/images/evento/expo/img16.jpg";
import img17 from "../../assets/images/evento/expo/img17.jpg";
import img18 from "../../assets/images/evento/expo/img18.jpg";
import img19 from "../../assets/images/evento/expo/img19.jpg";
import img20 from "../../assets/images/evento/expo/img20.jpg";
import img21 from "../../assets/images/evento/expo/img21.jpg";
import img22 from "../../assets/images/evento/expo/img22.jpg";
import img23 from "../../assets/images/evento/expo/img23.jpg";
import img24 from "../../assets/images/evento/expo/img24.jpg";
import img25 from "../../assets/images/evento/expo/img25.jpg";
import img26 from "../../assets/images/evento/expo/img26.jpg";
import img27 from "../../assets/images/evento/expo/img27.jpg";
import img28 from "../../assets/images/evento/expo/img28.jpg";
import img29 from "../../assets/images/evento/expo/img29.jpg";
import img30 from "../../assets/images/evento/expo/img30.jpg";
import img31 from "../../assets/images/evento/expo/img31.jpg";
import img32 from "../../assets/images/evento/expo/img32.jpg";
import img33 from "../../assets/images/evento/expo/img33.jpg";
import img34 from "../../assets/images/evento/expo/img34.jpg";
import img35 from "../../assets/images/evento/expo/img35.jpg";
import img36 from "../../assets/images/evento/expo/img36.jpg";
import img37 from "../../assets/images/evento/expo/img37.jpg";
import img38 from "../../assets/images/evento/expo/img38.jpg";
import img39 from "../../assets/images/evento/expo/img39.jpg";
import img40 from "../../assets/images/evento/expo/img40.jpg";
import img41 from "../../assets/images/evento/expo/img41.jpg";
import img42 from "../../assets/images/evento/expo/img42.jpg";
import img43 from "../../assets/images/evento/expo/img43.jpg";
import img44 from "../../assets/images/evento/expo/img44.jpg";
import img45 from "../../assets/images/evento/expo/img45.jpg";
import img46 from "../../assets/images/evento/expo/img46.jpg";
import img47 from "../../assets/images/evento/expo/img47.jpg";
import img48 from "../../assets/images/evento/expo/img48.jpg";
import img49 from "../../assets/images/evento/expo/img49.jpg";
import img50 from "../../assets/images/evento/expo/img50.jpg";
import img51 from "../../assets/images/evento/expo/img51.jpg";
import img52 from "../../assets/images/evento/expo/img52.jpg";
import img53 from "../../assets/images/evento/expo/img53.jpg";
import img54 from "../../assets/images/evento/expo/img54.jpg";
import img55 from "../../assets/images/evento/expo/img55.jpg";
import img56 from "../../assets/images/evento/expo/img56.jpg";
import img57 from "../../assets/images/evento/expo/img57.jpg";
import img58 from "../../assets/images/evento/expo/img58.jpg";
import img59 from "../../assets/images/evento/expo/img59.jpg";
import img60 from "../../assets/images/evento/expo/img60.jpg";
import img61 from "../../assets/images/evento/expo/img61.jpg";
import img62 from "../../assets/images/evento/expo/img62.jpg";
import img63 from "../../assets/images/evento/expo/img63.jpg";
import img64 from "../../assets/images/evento/expo/img64.jpg";
import img65 from "../../assets/images/evento/expo/img65.jpg";
import img66 from "../../assets/images/evento/expo/img66.jpg";
import img67 from "../../assets/images/evento/expo/img67.jpg";
import img68 from "../../assets/images/evento/expo/img68.jpg";
import img69 from "../../assets/images/evento/expo/img69.jpg";
import img70 from "../../assets/images/evento/expo/img70.jpg";
import img71 from "../../assets/images/evento/expo/img71.jpg";
import img72 from "../../assets/images/evento/expo/img72.jpg";
import img73 from "../../assets/images/evento/expo/img73.jpg";
import img74 from "../../assets/images/evento/expo/img74.jpg";
import img75 from "../../assets/images/evento/expo/img75.jpg";
import img76 from "../../assets/images/evento/expo/img76.jpg";
import img77 from "../../assets/images/evento/expo/img77.jpg";
import img78 from "../../assets/images/evento/expo/img78.jpg";
import img79 from "../../assets/images/evento/expo/img79.jpg";
import img80 from "../../assets/images/evento/expo/img80.jpg";
import img81 from "../../assets/images/evento/expo/img81.jpg";
import img82 from "../../assets/images/evento/expo/img82.jpg";
import img83 from "../../assets/images/evento/expo/img83.jpg";
import img84 from "../../assets/images/evento/expo/img84.jpg";
import img85 from "../../assets/images/evento/expo/img85.jpg";
import img86 from "../../assets/images/evento/expo/img86.jpg";
import img87 from "../../assets/images/evento/expo/img87.jpg";
import img88 from "../../assets/images/evento/expo/img88.jpg";
import img89 from "../../assets/images/evento/expo/img89.jpg";
import img90 from "../../assets/images/evento/expo/img90.jpg";
import img91 from "../../assets/images/evento/expo/img91.jpg";
import img92 from "../../assets/images/evento/expo/img92.jpg";
import img93 from "../../assets/images/evento/expo/img93.jpg";
import img94 from "../../assets/images/evento/expo/img94.jpg";
import img95 from "../../assets/images/evento/expo/img95.jpg";
import img96 from "../../assets/images/evento/expo/img96.jpg";
import img97 from "../../assets/images/evento/expo/img97.jpg";
import img98 from "../../assets/images/evento/expo/img98.jpg";
import img99 from "../../assets/images/evento/expo/img99.jpg";
import img100 from "../../assets/images/evento/expo/img100.jpg";
import img101 from "../../assets/images/evento/expo/img101.jpg";
import img102 from "../../assets/images/evento/expo/img102.jpg";
import img103 from "../../assets/images/evento/expo/img103.jpg";
import img104 from "../../assets/images/evento/expo/img104.jpg";
import img105 from "../../assets/images/evento/expo/img105.jpg";
import img106 from "../../assets/images/evento/expo/img106.jpg";
import img107 from "../../assets/images/evento/expo/img107.jpg";
import img108 from "../../assets/images/evento/expo/img108.jpg";
import img109 from "../../assets/images/evento/expo/img109.jpg";
import img110 from "../../assets/images/evento/expo/img110.jpg";
import img111 from "../../assets/images/evento/expo/img111.jpg";
import img112 from "../../assets/images/evento/expo/img112.jpg";
import img113 from "../../assets/images/evento/expo/img113.jpg";
import img114 from "../../assets/images/evento/expo/img114.jpg";
import img115 from "../../assets/images/evento/expo/img115.jpg";
import img116 from "../../assets/images/evento/expo/img116.jpg";
import img117 from "../../assets/images/evento/expo/img117.jpg";
import img118 from "../../assets/images/evento/expo/img118.jpg";
import img119 from "../../assets/images/evento/expo/img119.jpg";
import img120 from "../../assets/images/evento/expo/img120.jpg";
import img121 from "../../assets/images/evento/expo/img121.jpg";
import img122 from "../../assets/images/evento/expo/img122.jpg";
import img123 from "../../assets/images/evento/expo/img123.jpg";
import img124 from "../../assets/images/evento/expo/img124.jpg";
import img125 from "../../assets/images/evento/expo/img125.jpg";
import img126 from "../../assets/images/evento/expo/img126.jpg";
import img127 from "../../assets/images/evento/expo/img127.jpg";
import img128 from "../../assets/images/evento/expo/img128.jpg";
import img129 from "../../assets/images/evento/expo/img129.jpg";
import img130 from "../../assets/images/evento/expo/img130.jpg";
import img131 from "../../assets/images/evento/expo/img131.jpg";
import img132 from "../../assets/images/evento/expo/img132.jpg";
import img133 from "../../assets/images/evento/expo/img133.jpg";
import img134 from "../../assets/images/evento/expo/img134.jpg";
import img135 from "../../assets/images/evento/expo/img135.jpg";
import img136 from "../../assets/images/evento/expo/img136.jpg";
import img137 from "../../assets/images/evento/expo/img137.jpg";
import img138 from "../../assets/images/evento/expo/img138.jpg";
import img139 from "../../assets/images/evento/expo/img139.jpg";
import img140 from "../../assets/images/evento/expo/img140.jpg";
import img141 from "../../assets/images/evento/expo/img141.jpg";
import img142 from "../../assets/images/evento/expo/img142.jpg";
import img143 from "../../assets/images/evento/expo/img143.jpg";
import img144 from "../../assets/images/evento/expo/img144.jpg";
import img145 from "../../assets/images/evento/expo/img145.jpg";
import img146 from "../../assets/images/evento/expo/img146.jpg";
import img147 from "../../assets/images/evento/expo/img147.jpg";
import img148 from "../../assets/images/evento/expo/img148.jpg";
import img149 from "../../assets/images/evento/expo/img149.jpg";
import img150 from "../../assets/images/evento/expo/img150.jpg";
import img151 from "../../assets/images/evento/expo/img151.jpg";
import img152 from "../../assets/images/evento/expo/img152.jpg";
import img153 from "../../assets/images/evento/expo/img153.jpg";
import img154 from "../../assets/images/evento/expo/img154.jpg";
import img155 from "../../assets/images/evento/expo/img155.jpg";
import img156 from "../../assets/images/evento/expo/img156.jpg";
import img157 from "../../assets/images/evento/expo/img157.jpg";
import img158 from "../../assets/images/evento/expo/img158.jpg";
import img159 from "../../assets/images/evento/expo/img159.jpg";
import img160 from "../../assets/images/evento/expo/img160.jpg";
import img161 from "../../assets/images/evento/expo/img161.jpg";
import img162 from "../../assets/images/evento/expo/img162.jpg";
import img163 from "../../assets/images/evento/expo/img163.jpg";
import img164 from "../../assets/images/evento/expo/img164.jpg";
import img165 from "../../assets/images/evento/expo/img165.jpg";
import img166 from "../../assets/images/evento/expo/img166.jpg";
import img167 from "../../assets/images/evento/expo/img167.jpg";
import img168 from "../../assets/images/evento/expo/img168.jpg";
import img169 from "../../assets/images/evento/expo/img169.jpg";
import img170 from "../../assets/images/evento/expo/img170.jpg";
import img171 from "../../assets/images/evento/expo/img171.jpg";
import img172 from "../../assets/images/evento/expo/img172.jpg";
import img173 from "../../assets/images/evento/expo/img173.jpg";
import img174 from "../../assets/images/evento/expo/img174.jpg";
import img175 from "../../assets/images/evento/expo/img175.jpg";
import img176 from "../../assets/images/evento/expo/img176.jpg";
import img177 from "../../assets/images/evento/expo/img177.jpg";
import img178 from "../../assets/images/evento/expo/img178.jpg";
import img179 from "../../assets/images/evento/expo/img179.jpg";
import img180 from "../../assets/images/evento/expo/img180.jpg";
import img181 from "../../assets/images/evento/expo/img181.jpg";
import img182 from "../../assets/images/evento/expo/img182.jpg";
import img183 from "../../assets/images/evento/expo/img183.jpg";
import img184 from "../../assets/images/evento/expo/img184.jpg";
import img185 from "../../assets/images/evento/expo/img185.jpg";
import img186 from "../../assets/images/evento/expo/img186.jpg";
import img187 from "../../assets/images/evento/expo/img187.jpg";
import img188 from "../../assets/images/evento/expo/img188.jpg";
import img189 from "../../assets/images/evento/expo/img189.jpg";
import img190 from "../../assets/images/evento/expo/img190.jpg";
import img191 from "../../assets/images/evento/expo/img191.jpg";
import img192 from "../../assets/images/evento/expo/img192.jpg";
import img193 from "../../assets/images/evento/expo/img193.jpg";
import img194 from "../../assets/images/evento/expo/img194.jpg";
import img195 from "../../assets/images/evento/expo/img195.jpg";
import img196 from "../../assets/images/evento/expo/img196.jpg";
import img197 from "../../assets/images/evento/expo/img197.jpg";
import img198 from "../../assets/images/evento/expo/img198.jpg";
import img199 from "../../assets/images/evento/expo/img199.jpg";
import img200 from "../../assets/images/evento/expo/img200.jpg";
import img201 from "../../assets/images/evento/expo/img201.jpg";
import img202 from "../../assets/images/evento/expo/img202.jpg";
import img203 from "../../assets/images/evento/expo/img203.jpg";
import img204 from "../../assets/images/evento/expo/img204.jpg";
import img205 from "../../assets/images/evento/expo/img205.jpg";
import img206 from "../../assets/images/evento/expo/img206.jpg";
import img207 from "../../assets/images/evento/expo/img207.jpg";
import img208 from "../../assets/images/evento/expo/img208.jpg";
import img209 from "../../assets/images/evento/expo/img209.jpg";
import img210 from "../../assets/images/evento/expo/img210.jpg";
import img211 from "../../assets/images/evento/expo/img211.jpg";
import img212 from "../../assets/images/evento/expo/img212.jpg";
import img213 from "../../assets/images/evento/expo/img213.jpg";
import img214 from "../../assets/images/evento/expo/img214.jpg";
import img215 from "../../assets/images/evento/expo/img215.jpg";
import img216 from "../../assets/images/evento/expo/img216.jpg";
import img217 from "../../assets/images/evento/expo/img217.jpg";
import img218 from "../../assets/images/evento/expo/img218.jpg";
import img219 from "../../assets/images/evento/expo/img219.jpg";
import img220 from "../../assets/images/evento/expo/img220.jpg";
import img221 from "../../assets/images/evento/expo/img221.jpg";
import img222 from "../../assets/images/evento/expo/img222.jpg";
import img223 from "../../assets/images/evento/expo/img223.jpg";
import img224 from "../../assets/images/evento/expo/img224.jpg";
import img225 from "../../assets/images/evento/expo/img225.jpg";
import img226 from "../../assets/images/evento/expo/img226.jpg";
import img227 from "../../assets/images/evento/expo/img227.jpg";
import img228 from "../../assets/images/evento/expo/img228.jpg";
import img229 from "../../assets/images/evento/expo/img229.jpg";
import img230 from "../../assets/images/evento/expo/img230.jpg";
import img231 from "../../assets/images/evento/expo/img231.jpg";
import img232 from "../../assets/images/evento/expo/img232.jpg";
import img233 from "../../assets/images/evento/expo/img233.jpg";
import img234 from "../../assets/images/evento/expo/img234.jpg";
import img235 from "../../assets/images/evento/expo/img235.jpg";
import img236 from "../../assets/images/evento/expo/img236.jpg";
import img237 from "../../assets/images/evento/expo/img237.jpg";
import img238 from "../../assets/images/evento/expo/img238.jpg";
import img239 from "../../assets/images/evento/expo/img239.jpg";
import img240 from "../../assets/images/evento/expo/img240.jpg";
import img241 from "../../assets/images/evento/expo/img241.jpg";
import img242 from "../../assets/images/evento/expo/img242.jpg";
import img243 from "../../assets/images/evento/expo/img243.jpg";
import img244 from "../../assets/images/evento/expo/img244.jpg";
import img245 from "../../assets/images/evento/expo/img245.jpg";
import img246 from "../../assets/images/evento/expo/img246.jpg";
import img247 from "../../assets/images/evento/expo/img247.jpg";
import img248 from "../../assets/images/evento/expo/img248.jpg";
import img249 from "../../assets/images/evento/expo/img249.jpg";
import img250 from "../../assets/images/evento/expo/img250.jpg";
import img251 from "../../assets/images/evento/expo/img251.jpg";
import img252 from "../../assets/images/evento/expo/img252.jpg";
import img253 from "../../assets/images/evento/expo/img253.jpg";
import img254 from "../../assets/images/evento/expo/img254.jpg";
import img255 from "../../assets/images/evento/expo/img255.jpg";
import img256 from "../../assets/images/evento/expo/img256.jpg";
import img257 from "../../assets/images/evento/expo/img257.jpg";
import img258 from "../../assets/images/evento/expo/img258.jpg";
import img259 from "../../assets/images/evento/expo/img259.jpg";
import img260 from "../../assets/images/evento/expo/img260.jpg";
import img261 from "../../assets/images/evento/expo/img261.jpg";
import img262 from "../../assets/images/evento/expo/img262.jpg";
import img263 from "../../assets/images/evento/expo/img263.jpg";
import img264 from "../../assets/images/evento/expo/img264.jpg";
import img265 from "../../assets/images/evento/expo/img265.jpg";
import img266 from "../../assets/images/evento/expo/img266.jpg";
import img267 from "../../assets/images/evento/expo/img267.jpg";
import img268 from "../../assets/images/evento/expo/img268.jpg";
import img269 from "../../assets/images/evento/expo/img269.jpg";
import img270 from "../../assets/images/evento/expo/img270.jpg";
import img271 from "../../assets/images/evento/expo/img271.jpg";
import img272 from "../../assets/images/evento/expo/img272.jpg";
import img273 from "../../assets/images/evento/expo/img273.jpg";
import img274 from "../../assets/images/evento/expo/img274.jpg";
import img275 from "../../assets/images/evento/expo/img275.jpg";
import img276 from "../../assets/images/evento/expo/img276.jpg";
import img277 from "../../assets/images/evento/expo/img277.jpg";
import img278 from "../../assets/images/evento/expo/img278.jpg";
import img279 from "../../assets/images/evento/expo/img279.jpg";
import img280 from "../../assets/images/evento/expo/img280.jpg";
import img281 from "../../assets/images/evento/expo/img281.jpg";
import img282 from "../../assets/images/evento/expo/img282.jpg";
import img283 from "../../assets/images/evento/expo/img283.jpg";
import img284 from "../../assets/images/evento/expo/img284.jpg";
import img285 from "../../assets/images/evento/expo/img285.jpg";
import img286 from "../../assets/images/evento/expo/img286.jpg";
import img287 from "../../assets/images/evento/expo/img287.jpg";
import img288 from "../../assets/images/evento/expo/img288.jpg";
import img289 from "../../assets/images/evento/expo/img289.jpg";
import img290 from "../../assets/images/evento/expo/img290.jpg";
import img291 from "../../assets/images/evento/expo/img291.jpg";
import img292 from "../../assets/images/evento/expo/img292.jpg";
import img293 from "../../assets/images/evento/expo/img293.jpg";
import img294 from "../../assets/images/evento/expo/img294.jpg";
import img295 from "../../assets/images/evento/expo/img295.jpg";
import img296 from "../../assets/images/evento/expo/img296.jpg";
import img297 from "../../assets/images/evento/expo/img297.jpg";
import img298 from "../../assets/images/evento/expo/img298.jpg";
import img299 from "../../assets/images/evento/expo/img299.jpg";
import img300 from "../../assets/images/evento/expo/img300.jpg";
import img301 from "../../assets/images/evento/expo/img301.jpg";
import img302 from "../../assets/images/evento/expo/img302.jpg";
import img303 from "../../assets/images/evento/expo/img303.jpg";
import img304 from "../../assets/images/evento/expo/img304.jpg";
import img305 from "../../assets/images/evento/expo/img305.jpg";
import img306 from "../../assets/images/evento/expo/img306.jpg";
import img307 from "../../assets/images/evento/expo/img307.jpg";
import img308 from "../../assets/images/evento/expo/img308.jpg";
import img309 from "../../assets/images/evento/expo/img309.jpg";
import img310 from "../../assets/images/evento/expo/img310.jpg";
import img311 from "../../assets/images/evento/expo/img311.jpg";
import img312 from "../../assets/images/evento/expo/img312.jpg";
import img313 from "../../assets/images/evento/expo/img313.jpg";
import img314 from "../../assets/images/evento/expo/img314.jpg";
import img315 from "../../assets/images/evento/expo/img315.jpg";
import img316 from "../../assets/images/evento/expo/img316.jpg";
import img317 from "../../assets/images/evento/expo/img317.jpg";
import img318 from "../../assets/images/evento/expo/img318.jpg";
import img319 from "../../assets/images/evento/expo/img319.jpg";
import img320 from "../../assets/images/evento/expo/img320.jpg";
import img321 from "../../assets/images/evento/expo/img321.jpg";
import img322 from "../../assets/images/evento/expo/img322.jpg";
import img323 from "../../assets/images/evento/expo/img323.jpg";
import img324 from "../../assets/images/evento/expo/img324.jpg";
import img325 from "../../assets/images/evento/expo/img325.jpg";
import img326 from "../../assets/images/evento/expo/img326.jpg";
import img327 from "../../assets/images/evento/expo/img327.jpg";
import img328 from "../../assets/images/evento/expo/img328.jpg";
import img329 from "../../assets/images/evento/expo/img329.jpg";
import img330 from "../../assets/images/evento/expo/img330.jpg";
import img331 from "../../assets/images/evento/expo/img331.jpg";
import img332 from "../../assets/images/evento/expo/img332.jpg";
import img333 from "../../assets/images/evento/expo/img333.jpg";
import img334 from "../../assets/images/evento/expo/img334.jpg";
import img335 from "../../assets/images/evento/expo/img335.jpg";
import img336 from "../../assets/images/evento/expo/img336.jpg";
import img337 from "../../assets/images/evento/expo/img337.jpg";
import img338 from "../../assets/images/evento/expo/img338.jpg";
import img339 from "../../assets/images/evento/expo/img339.jpg";
import img340 from "../../assets/images/evento/expo/img340.jpg";
import img341 from "../../assets/images/evento/expo/img341.jpg";
import img342 from "../../assets/images/evento/expo/img342.jpg";
import img343 from "../../assets/images/evento/expo/img343.jpg";
import img344 from "../../assets/images/evento/expo/img344.jpg";
import img345 from "../../assets/images/evento/expo/img345.jpg";
import img346 from "../../assets/images/evento/expo/img346.jpg";
import img347 from "../../assets/images/evento/expo/img347.jpg";
import img348 from "../../assets/images/evento/expo/img348.jpg";
import img349 from "../../assets/images/evento/expo/img349.jpg";
import img350 from "../../assets/images/evento/expo/img350.jpg";
import img351 from "../../assets/images/evento/expo/img351.jpg";
import img352 from "../../assets/images/evento/expo/img352.jpg";
import img353 from "../../assets/images/evento/expo/img353.jpg";
import img354 from "../../assets/images/evento/expo/img354.jpg";
import img355 from "../../assets/images/evento/expo/img355.jpg";
import img356 from "../../assets/images/evento/expo/img356.jpg";
import img357 from "../../assets/images/evento/expo/img357.jpg";
import img358 from "../../assets/images/evento/expo/img358.jpg";
import img359 from "../../assets/images/evento/expo/img359.jpg";
import img360 from "../../assets/images/evento/expo/img360.jpg";
import img361 from "../../assets/images/evento/expo/img361.jpg";
import img362 from "../../assets/images/evento/expo/img362.jpg";
import img363 from "../../assets/images/evento/expo/img363.jpg";
import img364 from "../../assets/images/evento/expo/img364.jpg";
import img365 from "../../assets/images/evento/expo/img365.jpg";
import img366 from "../../assets/images/evento/expo/img366.jpg";
import img367 from "../../assets/images/evento/expo/img367.jpg";
import img368 from "../../assets/images/evento/expo/img368.jpg";
import img369 from "../../assets/images/evento/expo/img369.jpg";
import img370 from "../../assets/images/evento/expo/img370.jpg";
import img371 from "../../assets/images/evento/expo/img371.jpg";
import img372 from "../../assets/images/evento/expo/img372.jpg";
import img373 from "../../assets/images/evento/expo/img373.jpg";
import img374 from "../../assets/images/evento/expo/img374.jpg";
import img375 from "../../assets/images/evento/expo/img375.jpg";
import img376 from "../../assets/images/evento/expo/img376.jpg";
import img377 from "../../assets/images/evento/expo/img377.jpg";
import img378 from "../../assets/images/evento/expo/img378.jpg";
import img379 from "../../assets/images/evento/expo/img379.jpg";
import img380 from "../../assets/images/evento/expo/img380.jpg";
import img381 from "../../assets/images/evento/expo/img381.jpg";
import img382 from "../../assets/images/evento/expo/img382.jpg";
import img383 from "../../assets/images/evento/expo/img383.jpg";
import img384 from "../../assets/images/evento/expo/img384.jpg";
import img385 from "../../assets/images/evento/expo/img385.jpg";
import img386 from "../../assets/images/evento/expo/img386.jpg";
import img387 from "../../assets/images/evento/expo/img387.jpg";
import img388 from "../../assets/images/evento/expo/img388.jpg";
import img389 from "../../assets/images/evento/expo/img389.jpg";
import img390 from "../../assets/images/evento/expo/img390.jpg";
import img391 from "../../assets/images/evento/expo/img391.jpg";
import img392 from "../../assets/images/evento/expo/img392.jpg";
import img393 from "../../assets/images/evento/expo/img393.jpg";
import img394 from "../../assets/images/evento/expo/img394.jpg";
import img395 from "../../assets/images/evento/expo/img395.jpg";
import img396 from "../../assets/images/evento/expo/img396.jpg";
import img397 from "../../assets/images/evento/expo/img397.jpg";
import img398 from "../../assets/images/evento/expo/img398.jpg";
import img399 from "../../assets/images/evento/expo/img399.jpg";
import img400 from "../../assets/images/evento/expo/img400.jpg";
import img401 from "../../assets/images/evento/expo/img401.jpg";
import img402 from "../../assets/images/evento/expo/img402.jpg";
import img403 from "../../assets/images/evento/expo/img403.jpg";
import img404 from "../../assets/images/evento/expo/img404.jpg";
import img405 from "../../assets/images/evento/expo/img405.jpg";
import img406 from "../../assets/images/evento/expo/img406.jpg";
import img407 from "../../assets/images/evento/expo/img407.jpg";
import img408 from "../../assets/images/evento/expo/img408.jpg";
import img409 from "../../assets/images/evento/expo/img409.jpg";
import img410 from "../../assets/images/evento/expo/img410.jpg";
import img411 from "../../assets/images/evento/expo/img411.jpg";
import img412 from "../../assets/images/evento/expo/img412.jpg";
import img413 from "../../assets/images/evento/expo/img413.jpg";
import img414 from "../../assets/images/evento/expo/img414.jpg";
import img415 from "../../assets/images/evento/expo/img415.jpg";
import img416 from "../../assets/images/evento/expo/img416.jpg";
import img417 from "../../assets/images/evento/expo/img417.jpg";
import img418 from "../../assets/images/evento/expo/img418.jpg";
import img419 from "../../assets/images/evento/expo/img419.jpg";
import img420 from "../../assets/images/evento/expo/img420.jpg";
import img421 from "../../assets/images/evento/expo/img421.jpg";
import img422 from "../../assets/images/evento/expo/img422.jpg";
import img423 from "../../assets/images/evento/expo/img423.jpg";
import img424 from "../../assets/images/evento/expo/img424.jpg";
import img425 from "../../assets/images/evento/expo/img425.jpg";
import img426 from "../../assets/images/evento/expo/img426.jpg";
import img427 from "../../assets/images/evento/expo/img427.jpg";
import img428 from "../../assets/images/evento/expo/img428.jpg";
import img429 from "../../assets/images/evento/expo/img429.jpg";
import img430 from "../../assets/images/evento/expo/img430.jpg";
import img431 from "../../assets/images/evento/expo/img431.jpg";
import img432 from "../../assets/images/evento/expo/img432.jpg";
import img433 from "../../assets/images/evento/expo/img433.jpg";
import img434 from "../../assets/images/evento/expo/img434.jpg";
import img435 from "../../assets/images/evento/expo/img435.jpg";
import img436 from "../../assets/images/evento/expo/img436.jpg";
import img437 from "../../assets/images/evento/expo/img437.jpg";
import img438 from "../../assets/images/evento/expo/img438.jpg";
import img439 from "../../assets/images/evento/expo/img439.jpg";
import img440 from "../../assets/images/evento/expo/img440.jpg";
import img441 from "../../assets/images/evento/expo/img441.jpg";
import img442 from "../../assets/images/evento/expo/img442.jpg";
import img443 from "../../assets/images/evento/expo/img443.jpg";
import img444 from "../../assets/images/evento/expo/img444.jpg";
import img445 from "../../assets/images/evento/expo/img445.jpg";
import img446 from "../../assets/images/evento/expo/img446.jpg";
import img447 from "../../assets/images/evento/expo/img447.jpg";
import img448 from "../../assets/images/evento/expo/img448.jpg";
import img449 from "../../assets/images/evento/expo/img449.jpg";
import img450 from "../../assets/images/evento/expo/img450.jpg";
import img451 from "../../assets/images/evento/expo/img451.jpg";
import img452 from "../../assets/images/evento/expo/img452.jpg";
import img453 from "../../assets/images/evento/expo/img453.jpg";
import img454 from "../../assets/images/evento/expo/img454.jpg";
import img455 from "../../assets/images/evento/expo/img455.jpg";
import img456 from "../../assets/images/evento/expo/img456.jpg";
import img457 from "../../assets/images/evento/expo/img457.jpg";
import img458 from "../../assets/images/evento/expo/img458.jpg";
import img459 from "../../assets/images/evento/expo/img459.jpg";
import img460 from "../../assets/images/evento/expo/img460.jpg";
import img461 from "../../assets/images/evento/expo/img461.jpg";
import img462 from "../../assets/images/evento/expo/img462.jpg";
import img463 from "../../assets/images/evento/expo/img463.jpg";
import img464 from "../../assets/images/evento/expo/img464.jpg";
import img465 from "../../assets/images/evento/expo/img465.jpg";
import img466 from "../../assets/images/evento/expo/img466.jpg";
import img467 from "../../assets/images/evento/expo/img467.jpg";
import img468 from "../../assets/images/evento/expo/img468.jpg";
import img469 from "../../assets/images/evento/expo/img469.jpg";
import img470 from "../../assets/images/evento/expo/img470.jpg";
import img471 from "../../assets/images/evento/expo/img471.jpg";
import img472 from "../../assets/images/evento/expo/img472.jpg";
import img473 from "../../assets/images/evento/expo/img473.jpg";
import img474 from "../../assets/images/evento/expo/img474.jpg";
import img475 from "../../assets/images/evento/expo/img475.jpg";
import img476 from "../../assets/images/evento/expo/img476.jpg";
import img477 from "../../assets/images/evento/expo/img477.jpg";
import img478 from "../../assets/images/evento/expo/img478.jpg";
import img479 from "../../assets/images/evento/expo/img479.jpg";
import img480 from "../../assets/images/evento/expo/img480.jpg";
import img481 from "../../assets/images/evento/expo/img481.jpg";
import img482 from "../../assets/images/evento/expo/img482.jpg";
import img483 from "../../assets/images/evento/expo/img483.jpg";
import img484 from "../../assets/images/evento/expo/img484.jpg";
import img485 from "../../assets/images/evento/expo/img485.jpg";
import img486 from "../../assets/images/evento/expo/img486.jpg";
import img487 from "../../assets/images/evento/expo/img487.jpg";
import img488 from "../../assets/images/evento/expo/img488.jpg";
import img489 from "../../assets/images/evento/expo/img489.jpg";
import img490 from "../../assets/images/evento/expo/img490.jpg";
import img491 from "../../assets/images/evento/expo/img491.jpg";
import img492 from "../../assets/images/evento/expo/img492.jpg";
import img493 from "../../assets/images/evento/expo/img493.jpg";
import img494 from "../../assets/images/evento/expo/img494.jpg";
import img495 from "../../assets/images/evento/expo/img495.jpg";
import img496 from "../../assets/images/evento/expo/img496.jpg";
import img497 from "../../assets/images/evento/expo/img497.jpg";
import img498 from "../../assets/images/evento/expo/img498.jpg";
import img499 from "../../assets/images/evento/expo/img499.jpg";
import img500 from "../../assets/images/evento/expo/img500.jpg";
import img501 from "../../assets/images/evento/expo/img501.jpg";
import img502 from "../../assets/images/evento/expo/img502.jpg";
import img503 from "../../assets/images/evento/expo/img503.jpg";
import img504 from "../../assets/images/evento/expo/img504.jpg";
import img505 from "../../assets/images/evento/expo/img505.jpg";
import img506 from "../../assets/images/evento/expo/img506.jpg";
import img507 from "../../assets/images/evento/expo/img507.jpg";
import img508 from "../../assets/images/evento/expo/img508.jpg";
import img509 from "../../assets/images/evento/expo/img509.jpg";
import img510 from "../../assets/images/evento/expo/img510.jpg";
import img511 from "../../assets/images/evento/expo/img511.jpg";
import img512 from "../../assets/images/evento/expo/img512.jpg";
import img513 from "../../assets/images/evento/expo/img513.jpg";
import img514 from "../../assets/images/evento/expo/img514.jpg";
import img515 from "../../assets/images/evento/expo/img515.jpg";
import img516 from "../../assets/images/evento/expo/img516.jpg";
import img517 from "../../assets/images/evento/expo/img517.jpg";
import img518 from "../../assets/images/evento/expo/img518.jpg";
import img519 from "../../assets/images/evento/expo/img519.jpg";
import img520 from "../../assets/images/evento/expo/img520.jpg";
import img521 from "../../assets/images/evento/expo/img521.jpg";
import img522 from "../../assets/images/evento/expo/img522.jpg";
import img523 from "../../assets/images/evento/expo/img523.jpg";
import img524 from "../../assets/images/evento/expo/img524.jpg";
import img525 from "../../assets/images/evento/expo/img525.jpg";
import img526 from "../../assets/images/evento/expo/img526.jpg";
import img527 from "../../assets/images/evento/expo/img527.jpg";
import img528 from "../../assets/images/evento/expo/img528.jpg";
import img529 from "../../assets/images/evento/expo/img529.jpg";
import img530 from "../../assets/images/evento/expo/img530.jpg";
import img531 from "../../assets/images/evento/expo/img531.jpg";
import img532 from "../../assets/images/evento/expo/img532.jpg";
import img533 from "../../assets/images/evento/expo/img533.jpg";
import img534 from "../../assets/images/evento/expo/img534.jpg";
import img535 from "../../assets/images/evento/expo/img535.jpg";
import img536 from "../../assets/images/evento/expo/img536.jpg";
import img537 from "../../assets/images/evento/expo/img537.jpg";
import img538 from "../../assets/images/evento/expo/img538.jpg";
import img539 from "../../assets/images/evento/expo/img539.jpg";
import img540 from "../../assets/images/evento/expo/img540.jpg";
import img541 from "../../assets/images/evento/expo/img541.jpg";
import img542 from "../../assets/images/evento/expo/img542.jpg";
import img543 from "../../assets/images/evento/expo/img543.jpg";
import img544 from "../../assets/images/evento/expo/img544.jpg";
import img545 from "../../assets/images/evento/expo/img545.jpg";
import img546 from "../../assets/images/evento/expo/img546.jpg";
import img547 from "../../assets/images/evento/expo/img547.jpg";
import img548 from "../../assets/images/evento/expo/img548.jpg";
import img549 from "../../assets/images/evento/expo/img549.jpg";
import img550 from "../../assets/images/evento/expo/img550.jpg";
import img551 from "../../assets/images/evento/expo/img551.jpg";
import img552 from "../../assets/images/evento/expo/img552.jpg";
import img553 from "../../assets/images/evento/expo/img553.jpg";
import img554 from "../../assets/images/evento/expo/img554.jpg";
import img555 from "../../assets/images/evento/expo/img555.jpg";
import img556 from "../../assets/images/evento/expo/img556.jpg";
import img557 from "../../assets/images/evento/expo/img557.jpg";
import img558 from "../../assets/images/evento/expo/img558.jpg";
import img559 from "../../assets/images/evento/expo/img559.jpg";
import img560 from "../../assets/images/evento/expo/img560.jpg";
import img561 from "../../assets/images/evento/expo/img561.jpg";
import img562 from "../../assets/images/evento/expo/img562.jpg";
import img563 from "../../assets/images/evento/expo/img563.jpg";
import img564 from "../../assets/images/evento/expo/img564.jpg";
import img565 from "../../assets/images/evento/expo/img565.jpg";
import img566 from "../../assets/images/evento/expo/img566.jpg";
import img567 from "../../assets/images/evento/expo/img567.jpg";
import img568 from "../../assets/images/evento/expo/img568.jpg";
import img569 from "../../assets/images/evento/expo/img569.jpg";
import img570 from "../../assets/images/evento/expo/img570.jpg";
import img571 from "../../assets/images/evento/expo/img571.jpg";
import img572 from "../../assets/images/evento/expo/img572.jpg";
import img573 from "../../assets/images/evento/expo/img573.jpg";
import img574 from "../../assets/images/evento/expo/img574.jpg";
import img575 from "../../assets/images/evento/expo/img575.jpg";
import img576 from "../../assets/images/evento/expo/img576.jpg";

import stand01 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Kenworth.png";
import stand02 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Freightliner.png";
import stand03 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Navistar.png";
import stand04 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Isuzu.png";
import stand05 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Hino.png";
import stand06 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_CircuitoExterior.png";

import stand07 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Almex.png";
import stand08 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Alpura.png";
import stand09 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Bepensa.png";
import stand10 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Bimbo.png";
import stand11 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Cemex.png";
import stand12 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_CocaCola.png";
import stand13 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Estafeta.png";
import stand14 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_GrupoModelo.png";
import stand15 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Hesa.png";

import stand16 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Holcim.png";
import stand17 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Jumex.png";
import stand18 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_LaCorona.png";
import stand19 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Lala.png";
import stand20 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Marva.png";
import stand21 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_NorCaribe.png";
import stand22 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_PalacioDeHierro.png";
import stand23 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_PaqueteExpress.png";
import stand24 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_pilot.png";

import stand25 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Sigma.png";
import stand26 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Solistica.png";
import stand27 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Tem.png";
import stand28 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Tiusa.png";
import stand29 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Tms.png";
import stand30 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Transcooler.png";
import stand31 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Trate.png";
import stand32 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Zemog.png";
import stand33 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Zonte.png";

import { Link } from "react-router-dom";

import { PanZoom } from "react-easy-panzoom";

import { Element, scroller } from "react-scroll";

import stands from "../../assets/images/evento/expo/zonaStands_julio15.jpg";

for (let i = 1; i < 144; i++) {}

const partes = [
  {
    img: img1,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img2,
    link: "1",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img3,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img4,
    link: "2",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: true,
  },
  {
    img: img5,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img6,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img7,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img8,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img9,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img10,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img11,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img12,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img13,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img14,
    link: "1",
    mostrar: null,
    title: "Business Finland",
    img_interior: miniatura1,
    url: "/expo/business-finland",
    lugar: "right",
    clic: true,
  },
  {
    img: img15,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img16,
    link: "2",
    mostrar: null,
    title: "Business Sweden",
    img_interior: miniatura2,
    url: "/expo/business-sweden",
    lugar: "right",
    clic: true,
  },
  {
    img: img17,
    link: "3",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/essity",
    lugar: "right",
    clic: true,
  },
  {
    img: img18,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img19,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img20,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img21,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img22,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img23,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img24,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img25,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img26,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img27,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img28,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img29,
    link: "3",
    mostrar: null,
    title: "essity",
    img_interior: miniatura3,
    url: "/expo/essity",
    lugar: "right",
    clic: true,
  },
  {
    img: img30,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img31,
    link: "4",
    mostrar: null,
    title: "Haldor",
    img_interior: "",
    url: "/expo/haldor-topsoe",
    lugar: "right",
    clic: true,
  },
  {
    img: img32,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img33,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img34,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img35,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img36,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img37,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img38,
    link: "7",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/wartsila",
    lugar: "right",
    clic: true,
  },
  {
    img: img39,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img40,
    link: "5",
    mostrar: null,
    title: "Volvo Buses",
    img_interior: miniatura5,
    url: "/expo/volvo-buses",
    lugar: "right",
    clic: true,
  },
  {
    img: img41,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img42,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img43,
    link: "4",
    mostrar: null,
    title: "Haldor Topsoe",
    img_interior: miniatura4,
    url: "/expo/haldor-topsoe",
    lugar: "right",
    clic: true,
  },
  {
    img: img44,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img45,
    link: "6",
    mostrar: null,
    title: "Secretaría de Economía",
    img_interior: miniatura6,
    url: "/expo/secretaria-de-economia",
    lugar: "right",
    clic: true,
  },
  {
    img: img46,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img47,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img48,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img49,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img50,
    link: "7",
    mostrar: true,
    title: "Wärtsilä",
    img_interior: miniatura7,
    url: "/expo/wartsila",
    lugar: "right",
    clic: true,
  },
  {
    img: img51,
    link: "7",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/wartsila",
    lugar: "right",
    clic: true,
  },
  {
    img: img52,
    link: "5",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/volvo-buses",
    lugar: "right",
    clic: true,
  },
  {
    img: img53,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img54,
    link: "9",
    mostrar: true,
    title: "Volvo Cars",
    img_interior: miniatura9,
    url: "/expo/volvo-cars",
    lugar: "right",
    clic: true,
  },
  {
    img: img55,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img56,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img57,
    link: "6",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/secretaria-de-economia",
    lugar: "right",
    clic: true,
  },
  {
    img: img58,
    link: "8",
    mostrar: false,
    title: "Nordic Innovation",
    img_interior: miniatura8,
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img59,
    link: "8",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img60,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img61,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img62,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img63,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img64,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img65,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img66,
    link: "9",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/volvo-cars",
    lugar: "right",
    clic: true,
  },
  {
    img: img67,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img68,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img69,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img70,
    link: "8",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img71,
    link: "8",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img72,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img73,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img74,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img75,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img76,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img77,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img78,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img79,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img80,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img81,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img82,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img83,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img84,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img85,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img86,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img87,
    link: "12",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img88,
    link: "12",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img89,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img90,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img91,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img92,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img93,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img94,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img95,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img96,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img97,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img98,
    link: "11",
    mostrar: false,
    title: "Atlas Group",
    img_interior: miniatura11,
    url: "/expo/atlas-copco",
    lugar: "right",
    clic: true,
  },
  {
    img: img99,
    link: "12",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img100,
    link: "12",
    mostrar: true,
    title: "Tetra pack",
    img_interior: miniatura10,
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img101,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img102,
    link: "14",
    mostrar: false,
    title: "SCANIA",
    img_interior: miniatura12,
    url: "/expo/scania",
    lugar: "right",
    clic: true,
  },
  {
    img: img103,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img104,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img105,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img106,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img107,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img108,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img109,
    link: "11",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/atlas-copco",
    lugar: "right",
    clic: true,
  },
  {
    img: img110,
    link: "11",
    mostrar: true,
    title: "Atlas Copco",
    img_interior: miniatura11,
    url: "/expo/atlas-copco",
    lugar: "right",
    clic: true,
  },
  {
    img: img111,
    link: "16",
    mostrar: true,
    title: "P4G",
    img_interior: miniatura14,
    url: "/expo/p4g",
    lugar: "right",
    clic: true,
  },
  {
    img: img112,
    link: "15",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/palsgaard",
    lugar: "right",
    clic: false,
  },
  {
    img: img113,
    link: "15",
    mostrar: true,
    title: "Palsgard",
    img_interior: miniatura13,
    url: "/expo/palsgaard",
    lugar: "right",
    clic: true,
  },
  {
    img: img114,
    link: "14",
    mostrar: true,
    title: "SCANIA",
    img_interior: miniatura12,
    url: "/expo/scania",
    lugar: "right",
    clic: true,
  },
  {
    img: img115,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img116,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img117,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img118,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img119,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img120,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img121,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img122,
    link: "16",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/p4g",
    lugar: "right",
    clic: true,
  },
  {
    img: img123,
    link: "16",
    mostrar: false,
    title: "P4G",
    img_interior: miniatura14,
    url: "/expo/p4g",
    lugar: "right",
    clic: false,
  },
  {
    img: img124,
    link: "17",
    mostrar: true,
    title: "AMSDE",
    img_interior: miniatura15,
    url: "/expo/amsde",
    lugar: "right",
    clic: true,
  },
  {
    img: img125,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img126,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img127,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img128,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img129,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img130,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img131,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img132,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img133,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img134,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img135,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img136,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img137,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img138,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img139,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img140,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img141,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img142,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img143,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img144,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img145,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img146,
    link: "1",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img147,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img148,
    link: "2",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: true,
  },
  {
    img: img149,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img150,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img151,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img152,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img153,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img154,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img155,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img156,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img157,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img158,
    link: "1",
    mostrar: null,
    title: "Business Finland",
    img_interior: miniatura1,
    url: "/expo/business-finland",
    lugar: "right",
    clic: true,
  },
  {
    img: img159,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img160,
    link: "2",
    mostrar: null,
    title: "Business Sweden",
    img_interior: miniatura2,
    url: "/expo/business-sweden",
    lugar: "right",
    clic: true,
  },
  {
    img: img161,
    link: "3",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/essity",
    lugar: "right",
    clic: true,
  },
  {
    img: img162,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img163,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img164,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img165,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img166,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img167,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img168,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img169,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img170,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img171,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img172,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img173,
    link: "3",
    mostrar: null,
    title: "essity",
    img_interior: miniatura3,
    url: "/expo/essity",
    lugar: "right",
    clic: true,
  },
  {
    img: img174,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img175,
    link: "4",
    mostrar: null,
    title: "Haldor",
    img_interior: "",
    url: "/expo/haldor-topsoe",
    lugar: "right",
    clic: true,
  },
  {
    img: img176,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img177,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img178,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img179,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img180,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img181,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img182,
    link: "7",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/wartsila",
    lugar: "right",
    clic: true,
  },
  {
    img: img183,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img184,
    link: "5",
    mostrar: null,
    title: "Volvo Buses",
    img_interior: miniatura5,
    url: "/expo/volvo-buses",
    lugar: "right",
    clic: true,
  },
  {
    img: img185,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img186,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img187,
    link: "4",
    mostrar: null,
    title: "Haldor Topsoe",
    img_interior: miniatura4,
    url: "/expo/haldor-topsoe",
    lugar: "right",
    clic: true,
  },
  {
    img: img188,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img189,
    link: "6",
    mostrar: null,
    title: "Secretaría de Economía",
    img_interior: miniatura6,
    url: "/expo/secretaria-de-economia",
    lugar: "right",
    clic: true,
  },
  {
    img: img190,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img191,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img192,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img193,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img194,
    link: "7",
    mostrar: true,
    title: "Wärtsilä",
    img_interior: miniatura7,
    url: "/expo/wartsila",
    lugar: "right",
    clic: true,
  },
  {
    img: img195,
    link: "7",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/wartsila",
    lugar: "right",
    clic: true,
  },
  {
    img: img196,
    link: "5",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/volvo-buses",
    lugar: "right",
    clic: true,
  },
  {
    img: img197,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img198,
    link: "9",
    mostrar: true,
    title: "Volvo Cars",
    img_interior: miniatura9,
    url: "/expo/volvo-cars",
    lugar: "right",
    clic: true,
  },
  {
    img: img199,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img200,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img201,
    link: "6",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/secretaria-de-economia",
    lugar: "right",
    clic: true,
  },
  {
    img: img202,
    link: "8",
    mostrar: false,
    title: "Nordic Innovation",
    img_interior: miniatura8,
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img203,
    link: "8",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img204,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img205,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img206,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img207,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img208,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img209,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img210,
    link: "9",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/volvo-cars",
    lugar: "right",
    clic: true,
  },
  {
    img: img211,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img212,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img213,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img214,
    link: "8",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img215,
    link: "8",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img216,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img217,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img218,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img219,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img220,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img221,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img222,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img223,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img224,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img225,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img226,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img227,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img228,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img229,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img230,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img231,
    link: "12",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img232,
    link: "12",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img233,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img234,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img235,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img236,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img237,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img238,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img239,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img240,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img241,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img242,
    link: "11",
    mostrar: false,
    title: "Atlas Group",
    img_interior: miniatura11,
    url: "/expo/atlas-copco",
    lugar: "right",
    clic: true,
  },
  {
    img: img243,
    link: "12",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img244,
    link: "12",
    mostrar: true,
    title: "Tetra pack",
    img_interior: miniatura10,
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img245,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img246,
    link: "14",
    mostrar: false,
    title: "SCANIA",
    img_interior: miniatura12,
    url: "/expo/scania",
    lugar: "right",
    clic: true,
  },
  {
    img: img247,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img248,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img249,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img250,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img251,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img252,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img253,
    link: "11",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/atlas-copco",
    lugar: "right",
    clic: true,
  },
  {
    img: img254,
    link: "11",
    mostrar: true,
    title: "Atlas Copco",
    img_interior: miniatura11,
    url: "/expo/atlas-copco",
    lugar: "right",
    clic: true,
  },
  {
    img: img255,
    link: "16",
    mostrar: true,
    title: "P4G",
    img_interior: miniatura14,
    url: "/expo/p4g",
    lugar: "right",
    clic: true,
  },
  {
    img: img256,
    link: "15",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/palsgaard",
    lugar: "right",
    clic: false,
  },
  {
    img: img257,
    link: "15",
    mostrar: true,
    title: "Palsgard",
    img_interior: miniatura13,
    url: "/expo/palsgaard",
    lugar: "right",
    clic: true,
  },
  {
    img: img258,
    link: "14",
    mostrar: true,
    title: "SCANIA",
    img_interior: miniatura12,
    url: "/expo/scania",
    lugar: "right",
    clic: true,
  },
  {
    img: img259,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img260,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img261,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img262,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img263,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img264,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img265,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img266,
    link: "16",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/p4g",
    lugar: "right",
    clic: true,
  },
  {
    img: img267,
    link: "16",
    mostrar: false,
    title: "P4G",
    img_interior: miniatura14,
    url: "/expo/p4g",
    lugar: "right",
    clic: false,
  },
  {
    img: img268,
    link: "17",
    mostrar: true,
    title: "AMSDE",
    img_interior: miniatura15,
    url: "/expo/amsde",
    lugar: "right",
    clic: true,
  },
  {
    img: img269,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img270,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img271,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img272,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img273,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img274,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img275,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img276,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img277,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img278,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img279,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img280,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img281,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img282,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img283,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img284,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img285,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img286,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img287,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img288,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img289,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img290,
    link: "1",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img291,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img292,
    link: "2",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: true,
  },
  {
    img: img293,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img294,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img295,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img296,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img297,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img298,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img299,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img300,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img301,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img302,
    link: "1",
    mostrar: null,
    title: "Business Finland",
    img_interior: miniatura1,
    url: "/expo/business-finland",
    lugar: "right",
    clic: true,
  },
  {
    img: img303,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img304,
    link: "2",
    mostrar: null,
    title: "Business Sweden",
    img_interior: miniatura2,
    url: "/expo/business-sweden",
    lugar: "right",
    clic: true,
  },
  {
    img: img305,
    link: "3",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/essity",
    lugar: "right",
    clic: true,
  },
  {
    img: img306,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img307,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img308,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img309,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img310,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img311,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img312,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img313,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img314,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img315,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img316,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img317,
    link: "3",
    mostrar: null,
    title: "essity",
    img_interior: miniatura3,
    url: "/expo/essity",
    lugar: "right",
    clic: true,
  },
  {
    img: img318,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img319,
    link: "4",
    mostrar: null,
    title: "Haldor",
    img_interior: "",
    url: "/expo/haldor-topsoe",
    lugar: "right",
    clic: true,
  },
  {
    img: img320,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img321,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img322,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img323,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img324,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img325,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img326,
    link: "7",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/wartsila",
    lugar: "right",
    clic: true,
  },
  {
    img: img327,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img328,
    link: "5",
    mostrar: null,
    title: "Volvo Buses",
    img_interior: miniatura5,
    url: "/expo/volvo-buses",
    lugar: "right",
    clic: true,
  },
  {
    img: img329,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img330,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img331,
    link: "4",
    mostrar: null,
    title: "Haldor Topsoe",
    img_interior: miniatura4,
    url: "/expo/haldor-topsoe",
    lugar: "right",
    clic: true,
  },
  {
    img: img332,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img333,
    link: "6",
    mostrar: null,
    title: "Secretaría de Economía",
    img_interior: miniatura6,
    url: "/expo/secretaria-de-economia",
    lugar: "right",
    clic: true,
  },
  {
    img: img334,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img335,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img336,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img337,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img338,
    link: "7",
    mostrar: true,
    title: "Wärtsilä",
    img_interior: miniatura7,
    url: "/expo/wartsila",
    lugar: "right",
    clic: true,
  },
  {
    img: img339,
    link: "7",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/wartsila",
    lugar: "right",
    clic: true,
  },
  {
    img: img340,
    link: "5",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/volvo-buses",
    lugar: "right",
    clic: true,
  },
  {
    img: img341,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img342,
    link: "9",
    mostrar: true,
    title: "Volvo Cars",
    img_interior: miniatura9,
    url: "/expo/volvo-cars",
    lugar: "right",
    clic: true,
  },
  {
    img: img343,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img344,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img345,
    link: "6",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/secretaria-de-economia",
    lugar: "right",
    clic: true,
  },
  {
    img: img346,
    link: "8",
    mostrar: false,
    title: "Nordic Innovation",
    img_interior: miniatura8,
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img347,
    link: "8",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img348,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img349,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img350,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img351,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img352,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img353,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img354,
    link: "9",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/volvo-cars",
    lugar: "right",
    clic: true,
  },
  {
    img: img355,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img356,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img357,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img358,
    link: "8",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img359,
    link: "8",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img360,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img361,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img362,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img363,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img364,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img365,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img366,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img367,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img368,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img369,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img370,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img371,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img372,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img373,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img374,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img375,
    link: "12",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img376,
    link: "12",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img377,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img378,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img379,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img380,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img381,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img382,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img383,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img384,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img385,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img386,
    link: "11",
    mostrar: false,
    title: "Atlas Group",
    img_interior: miniatura11,
    url: "/expo/atlas-copco",
    lugar: "right",
    clic: true,
  },
  {
    img: img387,
    link: "12",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img388,
    link: "12",
    mostrar: true,
    title: "Tetra pack",
    img_interior: miniatura10,
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img389,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img390,
    link: "14",
    mostrar: false,
    title: "SCANIA",
    img_interior: miniatura12,
    url: "/expo/scania",
    lugar: "right",
    clic: true,
  },
  {
    img: img391,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img392,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img393,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img394,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img395,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img396,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img397,
    link: "11",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/atlas-copco",
    lugar: "right",
    clic: true,
  },
  {
    img: img398,
    link: "11",
    mostrar: true,
    title: "Atlas Copco",
    img_interior: miniatura11,
    url: "/expo/atlas-copco",
    lugar: "right",
    clic: true,
  },
  {
    img: img399,
    link: "16",
    mostrar: true,
    title: "P4G",
    img_interior: miniatura14,
    url: "/expo/p4g",
    lugar: "right",
    clic: true,
  },
  {
    img: img400,
    link: "15",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/palsgaard",
    lugar: "right",
    clic: false,
  },
  {
    img: img401,
    link: "15",
    mostrar: true,
    title: "Palsgard",
    img_interior: miniatura13,
    url: "/expo/palsgaard",
    lugar: "right",
    clic: true,
  },
  {
    img: img402,
    link: "14",
    mostrar: true,
    title: "SCANIA",
    img_interior: miniatura12,
    url: "/expo/scania",
    lugar: "right",
    clic: true,
  },
  {
    img: img403,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img404,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img405,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img406,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img407,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img408,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img409,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img410,
    link: "16",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/p4g",
    lugar: "right",
    clic: true,
  },
  {
    img: img411,
    link: "16",
    mostrar: false,
    title: "P4G",
    img_interior: miniatura14,
    url: "/expo/p4g",
    lugar: "right",
    clic: false,
  },
  {
    img: img412,
    link: "17",
    mostrar: true,
    title: "AMSDE",
    img_interior: miniatura15,
    url: "/expo/amsde",
    lugar: "right",
    clic: true,
  },
  {
    img: img413,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img414,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img415,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img416,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img417,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img418,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img419,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img420,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img421,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img422,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img423,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img424,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img425,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img426,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img427,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img428,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img429,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img430,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img431,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img432,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img433,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img434,
    link: "1",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img435,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img436,
    link: "2",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: true,
  },
  {
    img: img437,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img438,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img439,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img440,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img441,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img442,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img443,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img444,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img445,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img446,
    link: "1",
    mostrar: null,
    title: "Business Finland",
    img_interior: miniatura1,
    url: "/expo/business-finland",
    lugar: "right",
    clic: true,
  },
  {
    img: img447,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img448,
    link: "2",
    mostrar: null,
    title: "Business Sweden",
    img_interior: miniatura2,
    url: "/expo/business-sweden",
    lugar: "right",
    clic: true,
  },
  {
    img: img449,
    link: "3",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/essity",
    lugar: "right",
    clic: true,
  },
  {
    img: img450,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img451,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img452,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img453,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img454,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img455,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img456,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img457,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img458,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img459,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img460,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img461,
    link: "3",
    mostrar: null,
    title: "essity",
    img_interior: miniatura3,
    url: "/expo/essity",
    lugar: "right",
    clic: true,
  },
  {
    img: img462,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img463,
    link: "4",
    mostrar: null,
    title: "Haldor",
    img_interior: "",
    url: "/expo/haldor-topsoe",
    lugar: "right",
    clic: true,
  },
  {
    img: img464,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img465,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img466,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img467,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img468,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img469,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img470,
    link: "7",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/wartsila",
    lugar: "right",
    clic: true,
  },
  {
    img: img471,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img472,
    link: "5",
    mostrar: null,
    title: "Volvo Buses",
    img_interior: miniatura5,
    url: "/expo/volvo-buses",
    lugar: "right",
    clic: true,
  },
  {
    img: img473,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img474,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img475,
    link: "4",
    mostrar: null,
    title: "Haldor Topsoe",
    img_interior: miniatura4,
    url: "/expo/haldor-topsoe",
    lugar: "right",
    clic: true,
  },
  {
    img: img476,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img477,
    link: "6",
    mostrar: null,
    title: "Secretaría de Economía",
    img_interior: miniatura6,
    url: "/expo/secretaria-de-economia",
    lugar: "right",
    clic: true,
  },
  {
    img: img478,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img479,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img480,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img481,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img482,
    link: "7",
    mostrar: true,
    title: "Wärtsilä",
    img_interior: miniatura7,
    url: "/expo/wartsila",
    lugar: "right",
    clic: true,
  },
  {
    img: img483,
    link: "7",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/wartsila",
    lugar: "right",
    clic: true,
  },
  {
    img: img484,
    link: "5",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/volvo-buses",
    lugar: "right",
    clic: true,
  },
  {
    img: img485,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img486,
    link: "9",
    mostrar: true,
    title: "Volvo Cars",
    img_interior: miniatura9,
    url: "/expo/volvo-cars",
    lugar: "right",
    clic: true,
  },
  {
    img: img487,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img488,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img489,
    link: "6",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/secretaria-de-economia",
    lugar: "right",
    clic: true,
  },
  {
    img: img490,
    link: "8",
    mostrar: false,
    title: "Nordic Innovation",
    img_interior: miniatura8,
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img491,
    link: "8",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img492,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img493,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img494,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img495,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img496,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img497,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img498,
    link: "9",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/volvo-cars",
    lugar: "right",
    clic: true,
  },
  {
    img: img499,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img500,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img501,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img502,
    link: "8",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img503,
    link: "8",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/nordic-innovation",
    lugar: "right",
    clic: false,
  },
  {
    img: img504,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img505,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img506,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img507,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img508,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img509,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img510,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img511,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img512,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img513,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img514,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img515,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img516,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img517,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img518,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img519,
    link: "12",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img520,
    link: "12",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img521,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img522,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img523,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img524,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img525,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img526,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img527,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img528,
    link: "10",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/socios-mexicanos",
    lugar: "right",
    clic: true,
  },
  {
    img: img529,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img530,
    link: "11",
    mostrar: false,
    title: "Atlas Group",
    img_interior: miniatura11,
    url: "/expo/atlas-copco",
    lugar: "right",
    clic: true,
  },
  {
    img: img531,
    link: "12",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img532,
    link: "12",
    mostrar: true,
    title: "Tetra pack",
    img_interior: miniatura10,
    url: "/expo/tetra-pack",
    lugar: "right",
    clic: true,
  },
  {
    img: img533,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img534,
    link: "14",
    mostrar: false,
    title: "SCANIA",
    img_interior: miniatura12,
    url: "/expo/scania",
    lugar: "right",
    clic: true,
  },
  {
    img: img535,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img536,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img537,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img538,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img539,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img540,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img541,
    link: "11",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/atlas-copco",
    lugar: "right",
    clic: true,
  },
  {
    img: img542,
    link: "11",
    mostrar: true,
    title: "Atlas Copco",
    img_interior: miniatura11,
    url: "/expo/atlas-copco",
    lugar: "right",
    clic: true,
  },
  {
    img: img543,
    link: "16",
    mostrar: true,
    title: "P4G",
    img_interior: miniatura14,
    url: "/expo/p4g",
    lugar: "right",
    clic: true,
  },
  {
    img: img544,
    link: "15",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/palsgaard",
    lugar: "right",
    clic: false,
  },
  {
    img: img545,
    link: "15",
    mostrar: true,
    title: "Palsgard",
    img_interior: miniatura13,
    url: "/expo/palsgaard",
    lugar: "right",
    clic: true,
  },
  {
    img: img546,
    link: "14",
    mostrar: true,
    title: "SCANIA",
    img_interior: miniatura12,
    url: "/expo/scania",
    lugar: "right",
    clic: true,
  },
  {
    img: img547,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img548,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img549,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img550,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img551,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img552,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img553,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img554,
    link: "16",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "/expo/p4g",
    lugar: "right",
    clic: true,
  },
  {
    img: img555,
    link: "16",
    mostrar: false,
    title: "P4G",
    img_interior: miniatura14,
    url: "/expo/p4g",
    lugar: "right",
    clic: false,
  },
  {
    img: img556,
    link: "17",
    mostrar: true,
    title: "AMSDE",
    img_interior: miniatura15,
    url: "/expo/amsde",
    lugar: "right",
    clic: true,
  },
  {
    img: img557,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img558,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img559,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img560,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img561,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img562,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img563,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img564,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img565,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img566,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img567,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img568,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img569,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img570,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img571,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img572,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img573,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img574,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img575,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
  {
    img: img576,
    link: "",
    mostrar: null,
    title: "",
    img_interior: "",
    url: "",
    lugar: "right",
    clic: false,
  },
];

let audio;

const app = new Firebase();

const nombre_evento = "premio-2023";

const { Option } = Select;

const Expo = (props) => {
  const [ver_modal, setVerModal] = useState(false);
  const { usuario, datos } = useContext(Auth);
  const contenedor_ref = useRef(null);
  const contenedor_expo = useRef(null);
  const contenedor_zoom = useRef(null);
  const contenedor_zoom_movil = useRef(null);
  const [id_stand, setIdStand] = useState("");
  const [dia, setDia] = useState(3);
  const [etapa, setEtapa] = useState(3);
  const [url_etapa, setEtapaUrl] = useState("");
  const [estados_conin, setEstadosConin] = useState(0);
  const [play, setPlay] = useState(false);
  const [audios, setAudio] = useState("");

  useEffect(() => {
    if (contenedor_zoom.current) {
      //scroller.scrollTo('expo');
      contenedor_zoom.current.zoomToElement(id_stand, 5, 300, "easeOut");
    }

    if (contenedor_zoom_movil.current) {
      //scroller.scrollTo('expo');
      contenedor_zoom_movil.current.zoomToElement(id_stand, 10, 400, "easeOut");
    }
  }, [id_stand]);

  const almacenarClicStand = (nombre_pat) => {
    if (usuario) {
      app.escribir(
        nombre_evento + `/stands/${nombre_pat}/stand`,
        undefined,
        datos,
        () => {}
      );
    }
  };

  useEffect(() => {
    if (usuario) {
      app.obtener_objetos(
        nombre_evento + "/configuracion_flujo",
        true,
        (data) => {
          setEtapa(data[0].etapa);
        }
      );

      app.obtener_objetos(nombre_evento + "/configuracion", true, (data) => {
        setDia(data[0].dia_metricas);
      });
    }
  }, []);

  useEffect(() => {
    if (etapa !== 3) {
      switch (etapa) {
        case 0:
        case 1:
        case 2:
        case 4:
        case 6:
          setEtapaUrl("/");
          break;
        default:
          setEtapaUrl("");
          break;
      }
    }
  }, [etapa]);

  const mensajes_conin = [
    {
      etapa: 0,
      mensaje: <p>Tenemos un mensaje de bienvenida</p>,
      mensaje_inferior: <p></p>,
      url: "/bienvenida",
      img: img555,
      youtube:
        "https://www.youtube.com/embed/7GYj9oc3Ovw?start=780;rel=0;autoplay=1;",
      comentarios: false,
    },
    {
      etapa: 1,
      mensaje: <p>Te invitamos a nuestra conferencia motivacional</p>,
      mensaje_inferior: <p></p>,
      url: "/conferencia-motivacional",
      img: img555,
      youtube:
        "https://www.youtube.com/embed/7GYj9oc3Ovw?start=780;rel=0;autoplay=1;",
      comentarios: true,
    },
  ];

  useEffect(() => {
    switch (estados_conin) {
      case 0:
        setAudio(audio_expo);
        break;
      default:
        setAudio("");
        break;
    }
  }, [estados_conin]);

  const Hablar = () => {
    setPlay(true);
    audio = new Audio(audios);
    audio.addEventListener("ended", () => {
      setEstadosConin(2);
      setPlay(false);
    });
    audio.play();
  };

  useEffect(() => {
    if (play) {
      setEstadosConin(1);
    }
  }, [play]);

  const { ocultar = false } = props;

  const clics_imagenes = [
    //{nombre:'ANTP', ur: '/expo/casa-segura', nombre_fire:'antp',min: miniatura7, clics:[1,2,3]},//[15,27]},
    {
      nombre: "Bienvenida",
      ur: "/bienvenida",
      nombre_fire: "bienvenida",
      min: miniatura7,
      clics: [
        27, 50, 51, 52, 73, 74, 75, 76, 77, 97, 98, 99, 100, 101, 121, 122, 123,
        124, 125, 145, 146, 147, 148, 149, 168, 169, 170, 171, 172, 192, 193,
        194, 195, 217, 218,
      ],
      tipo_url: 0,
    },
    {
      nombre: "Sala de conferencias",
      ur: "/conferencia-motivacional",
      nombre_fire: "sala-conferencias",
      min: miniatura7,
      clics: [
        34, 57, 58, 59, 80, 81, 82, 83, 84, 103, 104, 105, 106, 107, 108, 127,
        127, 128, 129, 130, 131, 132, 152, 153, 154, 155, 156, 177, 178, 179,
        180, 201, 202, 203, 204, 227,
      ],
      tipo_url: 0,
    },
    {
      nombre: "Sala de premiación",
      ur: "/felicitacion-conductor",
      nombre_fire: "sala-premiacion",
      min: miniatura7,
      clics: [
        40, 41, 63, 64, 65, 66, 87, 88, 89, 90, 111, 112, 113, 114, 135, 136,
        137, 138, 159, 160, 161, 162, 184, 185,
      ],
      tipo_url: 0,
    },
    {
      nombre: "Sala de premiacion a operadores",
      ur: "/felicitacion-conductor",
      nombre_fire: "sala-de-premiacion-deoperadores",
      min: miniatura7,
      clics: [
        92, 116, 117, 139, 140, 141, 142, 163, 164, 165, 166, 187, 188, 189,
        190, 210, 211, 212, 213, 214, 215, 234, 235, 236, 237, 238, 239, 259,
        260, 261, 262, 263, 284, 285, 286, 287, 309, 310,
      ],
      tipo_url: 0,
    },
    {
      nombre: "Grupo Nor y Caribe",
      ur: "http://gruponorycaribe.com/",
      nombre_fire: "nt",
      min: miniatura7,
      clics: [150, 173, 174, 197, 198],
      tipo_url: 1,
    },
    {
      nombre: "META",
      ur: "https://libramientoslp.mx/",
      nombre_fire: "meta",
      min: miniatura7,
      clics: [176, 199, 200, 22, 223, 246],
      tipo_url: 1,
    }, //
    {
      nombre: "TEM logistica",
      ur: "https://temlogistica.com.mx/",
      nombre_fire: "tem-logistica",
      min: miniatura7,
      clics: [201, 225, 248, 249, 272],
      tipo_url: 1,
    },
    {
      nombre: "Kenworth",
      ur: "https://www.kenworth.com.mx/",
      nombre_fire: "kenworth",
      min: miniatura7,
      clics: [182, 206, 207, 231],
      tipo_url: 1,
    },
    {
      nombre: "Alpura",
      ur: "https://alpura.com/",
      nombre_fire: "alpura",
      min: miniatura7,
      clics: [250, 273, 274, 297, 298, 321],
      tipo_url: 1,
    },
    {
      nombre: "Navistar",
      ur: "/expo/navistar",
      nombre_fire: "navistar",
      min: miniatura7,
      clics: [252, 276, 277, 301],
      tipo_url: 2,
    },
    {
      nombre: "Palacio de Hierro",
      ur: "https://www.elpalaciodehierro.com/",
      nombre_fire: "palacio-de-hierro",
      min: miniatura7,
      clics: [205, 229, 230, 254],
      tipo_url: 1,
    },
    {
      nombre: "Bimbo",
      ur: "https://www.grupobimbo.com/es/nuestras-marcas",
      nombre_fire: "Bimbo",
      min: miniatura7,
      clics: [257, 258, 281, 281, 306, 307],
      tipo_url: 1,
    },
    {
      nombre: "Trate Logística",
      ur: "https://www.tratelogistica.com/PortalTrate/",
      nombre_fire: "trate",
      min: miniatura7,
      clics: [280, 304, 305, 329, 330],
      tipo_url: 1,
    },
    {
      nombre: "SCANIA",
      ur: "/expo/scania",
      nombre_fire: "scania",
      min: miniatura7,
      clics: [327, 351, 352, 376, 377],
      tipo_url: 2,
    },
    {
      nombre: "Transporte Monroy Schiavon",
      ur: "https://tms.com.mx/",
      nombre_fire: "tms",
      min: miniatura7,
      clics: [356, 379, 380, 403],
      tipo_url: 1,
    },
    {
      nombre: "HESA",
      ur: "http://www.hesa.com.mx/",
      nombre_fire: "hesa",
      min: miniatura7,
      clics: [382, 405, 406, 428, 429, 452],
      tipo_url: 1,
    },
    {
      nombre: "Jumex",
      ur: "http://grupojumex.mx/",
      nombre_fire: "jumex",
      min: miniatura7,
      clics: [324, 348, 371, 372, 395],
      tipo_url: 1,
    },
    {
      nombre: "Almex",
      ur: "https://almex.com.mx/",
      nombre_fire: "almex",
      min: miniatura7,
      clics: [374, 397, 398, 420, 421],
      tipo_url: 1,
    },
    {
      nombre: "Sigma",
      ur: "https://www.sigma-alimentos.com/",
      nombre_fire: "sigma",
      min: miniatura7,
      clics: [400, 423, 424, 447, 448, 472],
      tipo_url: 1,
    },
    {
      nombre: "LALA",
      ur: "https://www.lala.com.mx/",
      nombre_fire: "lala",
      min: miniatura7,
      clics: [474, 498, 499, 523],
      tipo_url: 1,
    },
    {
      nombre: "Solistica",
      ur: "https://solistica.com/",
      nombre_fire: "solistica",
      min: miniatura7,
      clics: [501, 525, 526, 550],
      tipo_url: 1,
    },
    {
      nombre: "FEMSA",
      ur: "https://www.femsa.com/es/",
      nombre_fire: "fensa",
      min: miniatura7,
      clics: [469, 520, 521, 545],
      tipo_url: 1,
    },
    {
      nombre: "Zonte",
      ur: "https://zonte.com.mx/",
      nombre_fire: "zonte",
      min: miniatura7,
      clics: [219, 220, 243, 244, 268, 269, 294],
      tipo_url: 1,
    },
    {
      nombre: "Circuito Exterior Mexiquense",
      ur: "https://www.circuitoexterior.mx/",
      nombre_fire: "circuito-exterior",
      min: miniatura7,
      clics: [266, 290, 291, 315, 316],
      tipo_url: 1,
    },
    {
      nombre: "Fletes Astro",
      ur: "https://fletes-astro.negocio.site/",
      nombre_fire: "astro",
      min: miniatura7,
      clics: [366, 390, 391, 415],
      tipo_url: 1,
    },
    {
      nombre: "Holcim",
      ur: "https://www.holcim.com.mx/",
      nombre_fire: "holcim",
      min: miniatura7,
      clics: [319, 343, 344, 368, 369],
      tipo_url: 1,
    },
    {
      nombre: "Fabrica de Jabón la Corona",
      ur: "https://www.lacorona.com.mx/",
      nombre_fire: "fabrica-de-jabon-la-corona",
      min: miniatura7,
      clics: [418, 442, 443, 467, 468],
      tipo_url: 1,
    },
    {
      nombre: "Esgari",
      ur: "https://www.esgari.com/",
      nombre_fire: "esgari",
      min: miniatura7,
      clics: [494, 517, 518, 540, 541, 564],
      tipo_url: 1,
    },
    {
      nombre: "Transportes Marva",
      ur: "https://www.marva.com.mx/",
      nombre_fire: "tm",
      min: miniatura7,
      clics: [464, 465, 488, 489, 490, 513, 514],
      tipo_url: 1,
    },
    {
      nombre: "Estafeta",
      ur: "https://www.estafeta.com/",
      nombre_fire: "estafeta",
      min: miniatura7,
      clics: [486, 487, 510, 511, 535, 536, 560],
      tipo_url: 1,
    },
    {
      nombre: "Paquetexpress",
      ur: "https://www.paquetexpress.com.mx/",
      nombre_fire: "paquetexpress",
      min: miniatura7,
      clics: [484, 485, 507, 508, 531],
      tipo_url: 1,
    },
    {
      nombre: "CEMEX",
      ur: "https://www.cemexmexico.com/",
      nombre_fire: "cemex",
      min: miniatura7,
      clics: [412, 435, 436, 459],
      tipo_url: 1,
    },
    {
      nombre: "Pilot",
      ur: "https://atpilot.mx/",
      nombre_fire: "pilot",
      min: miniatura7,
      clics: [386, 410, 433, 434, 457],
      tipo_url: 1,
    },
    {
      nombre: "Freightliner",
      ur: "https://www.freightliner.com.mx/",
      nombre_fire: "freightliner",
      min: miniatura7,
      clics: [337, 338, 361, 362, 384, 385],
      tipo_url: 1,
    },
    {
      nombre: "Grupo Modelo",
      ur: "https://portal.grupomodelo.com/login",
      nombre_fire: "grupo-modelo",
      min: miniatura7,
      clics: [299, 322, 323, 346],
      tipo_url: 1,
    },
    //{nombre:'', ur: '', nombre_fire:'',min: miniatura7, clics:[]},
  ];

  let ver_mosaico = partes.map((seccion, index) => {
    let existe_id = false;
    let existe_clic = false;

    let elemento = {};
    for (let i = 0; i < clics_imagenes.length; i++) {
      for (let j = 0; j < clics_imagenes[i].clics.length; j++) {
        if (index === clics_imagenes[i].clics[j]) {
          if (j === 0) {
            existe_id = true;
          }
          existe_clic = true;
          elemento = clics_imagenes[i];
          break;
        }
      }
      if (existe_clic) {
        break;
      }
    }

    if (existe_clic) {
      return <>okok</>;
      // return (
      //   <>
      //     {elemento.tipo_url === 0 ? (
      //       <Col
      //         id={existe_id ? elemento.nombre : ""}
      //         span={1}
      //         offset={0}
      //         className={"ponter_select"}
      //         onClick={() => {
      //           almacenarClicStand(elemento.nombre_fire.toLowerCase());
      //         }}
      //       >
      //         <Link to={elemento.ur}>
      //           <img
      //             src={seccion.img}
      //             className={
      //               existe_id
      //                 ? "img_expo_mosaico_principal img_opacity_0"
      //                 : "img_expo_mosaico_principal img_opacity_0"
      //             }
      //             alt={elemento.nombre}
      //           />
      //         </Link>
      //       </Col>
      //     ) : elemento.tipo_url === 1 ? (
      //       <Col
      //         id={existe_id ? elemento.nombre : ""}
      //         span={1}
      //         offset={0}
      //         className={"ponter_select"}
      //         onClick={() => {
      //           almacenarClicStand(elemento.nombre_fire.toLowerCase());
      //           window.open(
      //             elemento.ur,
      //             "Aviso de privacidad",
      //             "top=100,left=500,width=600,height=600"
      //           );
      //         }}
      //       >
      //         <img
      //           src={seccion.img}
      //           className={
      //             existe_id
      //               ? "img_expo_mosaico_principal img_opacity_0"
      //               : "img_expo_mosaico_principal img_opacity_0"
      //           }
      //           alt={elemento.nombre}
      //           onClick={() => {
      //             window.open(
      //               elemento.ur,
      //               "Aviso de privacidad",
      //               "top=100,left=500,width=600,height=600"
      //             );
      //           }}
      //         />
      //       </Col>
      //     ) : (
      //       <Col
      //         id={existe_id ? elemento.nombre : ""}
      //         span={1}
      //         offset={0}
      //         className={"ponter_select"}
      //         onClick={() => {
      //           almacenarClicStand(elemento.nombre_fire.toLowerCase());
      //           window.open(
      //             elemento.ur,
      //             "Aviso de privacidad",
      //             "top=100,left=500,width=600,height=600"
      //           );
      //         }}
      //       >
      //         <img
      //           src={seccion.img}
      //           className={
      //             existe_id
      //               ? "img_expo_mosaico_principal img_opacity_0"
      //               : "img_expo_mosaico_principal img_opacity_0"
      //           }
      //           alt={elemento.nombre}
      //           onClick={() => {
      //             window.open(
      //               elemento.ur,
      //               "Aviso de privacidad",
      //               "top=100,left=500,width=600,height=600"
      //             );
      //           }}
      //         />
      //       </Col>
      //     )}
      //   </>
      // );
    } else {
      console.log(seccion.img);
      return (
        <Col span={1} offset={0} className={"ponter_select_no"}>
          <img
            src={seccion.img}
            className="img_expo_mosaico_principal img_opacity_0"
            alt="Zona expo"
          />
        </Col>
      );
      // return (
      //   <Col span={1} offset={0} className={"ponter_select_no"}>
      //     <img
      //       src={seccion.img}
      //       className="img_expo_mosaico_principal img_opacity_0"
      //       alt="Zona expo"
      //     />
      //   </Col>
      // );
    }
  });

  return (
    <>
      {!ocultar && url_etapa !== "" && <Redirect to={url_etapa} />}
      <Row
        ref={contenedor_expo}
        style={{
          background: "url(" + fondo_expo + ") #e5e5e5",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
        }}
      >
        <Col span={24}></Col>
        <Col xs={0} xl={24}>
          <br />
        </Col>

        {!ocultar && (
          <>
            <Col span={24} style={{ display: "none" }}>
              <h3 className="titulo_agenda_evento">EXPO</h3>
              <Element name="expo" />
            </Col>
            <Col
              xs={{ span: 0 }}
              md={{ span: 0 }}
              className="contenedor_menu"
              style={{ display: "none" }}
            >
              <Menu>
                <Menu.ItemGroup key="g1" title="Selecciona un stand">
                  {clics_imagenes.map((stand, index) => {
                    return (
                      <Menu.Item
                        onClick={() => {
                          contenedor_zoom.current.resetTransform();
                          setIdStand(stand.nombre);
                        }}
                        key={stand.nombre}
                      >
                        {stand.nombre}
                      </Menu.Item>
                    );
                  })}
                </Menu.ItemGroup>
              </Menu>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 0 }} style={{ display: "none" }}>
              <Select
                style={{ width: "100%", backgroundColor: "green" }}
                onChange={(val) => {
                  contenedor_zoom_movil.current.resetTransform();
                  setIdStand(val);
                }}
              >
                <Option value={null} selected>
                  Elige un stand
                </Option>
                {clics_imagenes.map((stand, index) => {
                  return <Option value={stand.nombre}>{stand.nombre}</Option>;
                })}
              </Select>
              <br />
            </Col>
          </>
        )}
        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: !ocultar ? 24 : 24, offset: !ocultar ? 0 : 0 }}
        >
          <Row ref={contenedor_ref} style={{ paddingLeft: "6%" }}>
            <Col
              xs={{ span: 8 }}
              md={{ span: 3, offset: 2 }}
              style={{ marginTop: "32%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand01/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand01} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 3 }}
              style={{ marginTop: "29%", cursor: "pointer" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand02/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand02} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 3 }}
              style={{ marginTop: "26%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand03/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand03} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 3 }}
              style={{ marginTop: "26%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand04/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand04} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 3 }}
              style={{ marginTop: "29%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand05/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand05} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 3 }}
              style={{ marginTop: "32%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand06/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand06} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
          </Row>

          <Row ref={contenedor_ref} style={{ paddingLeft: "0%" }}>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2, offset: 3 }}
              style={{ marginTop: "3%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand07/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand07} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "1%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand08/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand08} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "0%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand09/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand09} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "-2%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand10/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand10} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "-4%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand11/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand11} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "-2%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand12/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand12} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "0%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand13/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand13} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "1%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand14/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand14} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "3%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand15/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand15} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
          </Row>

          <Row ref={contenedor_ref} style={{ paddingLeft: "0%" }}>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2, offset: 3 }}
              style={{ marginTop: "3%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand16/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand16} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "1%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand17/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand17} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "0%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand18/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand18} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "-2%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand19/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand19} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "-4%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand20/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand20} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "-2%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand21/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand21} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "0%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand22/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand22} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "1%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand23/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand23} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "3%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand24/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand24} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
          </Row>

          <Row ref={contenedor_ref} style={{ paddingLeft: "0%" }}>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2, offset: 3 }}
              style={{ marginTop: "3%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand25/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand25} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "1%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand26/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand26} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "0%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand27/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand27} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "-2%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand28/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand28} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "-4%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand29/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand29} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "-2%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand30/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand30} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "0%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand31/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand31} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "1%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand32/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand32} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
            <Col
              xs={{ span: 8 }}
              md={{ span: 2 }}
              style={{ marginTop: "3%" }}
              onClick={() => {
                //almacenarClicStand(elemento.nombre_fire.toLowerCase());
                window.open(
                  "/expo/stand33/",
                  "Aviso de privacidad",
                  "top=100,left=500,width=600,height=600"
                );
              }}
            >
              <img src={stand33} style={{ width: "100%", cursor: "pointer" }} />
            </Col>
          </Row>
        </Col>
      </Row>
      {etapa != 5 &&
      <>
      {!ocultar && estados_conin === 0 && (
        <img src={conin_inicial} className="conin" alt="" onClick={Hablar} />
      )}
      {!ocultar && estados_conin === 1 && (
        <>
          <img src={conin_hablar} className="conin" alt="" />
          <p
            className="saltar_conin"
            alt=""
            onClick={() => {
              setEstadosConin(2);
              setPlay(false);
              audio.pause();
            }}
          >
            SALTAR
          </p>
        </>
      )}
      </>}
      {}
    </>
  );
};

export default Expo;
