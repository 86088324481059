import React, { useState, useContext, useEffect, useRef } from "react";
import { Row, Col, Form, Input, Button, Modal, Select, Spin, message, notification } from "antd";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import  Firebase  from "../../utils/firebaseConfig";
import { Auth } from "../../utils/AuthContext";
import Errores from './Errores'
import '../../assets/css/registro.css';

import entrar from '../../assets/images/temp/image200x100.png';
import registrar from '../../assets/images/temp/image200x100.png';

const app = new Firebase();

let function_recaptcha;

const { Option } = Select;

let recaptcha_phone;

let confirmacionCodigo;

const nombre_webinar = 'premio-2023';

const mensaje = (tipo,titulo, texto) =>{

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}
const Login = ({ history, onResponse }) => {

    const { usuario } = useContext(Auth);
    const [error, seterror] = useState('');
    const [correo_recuperar, setCorreoRecuperar] = useState('');
    const [ingresar_codigo, setIngresarCodigo] = useState(false);
    const [cargando_login, setCargandoLogin] = useState(false);

    const [form] = Form.useForm();
    
    const ref_codigo = useRef(null);
    const ref_form_login = useRef(null);

    useEffect(() => {

        console.log('usuario');
        console.log(usuario);

        if (usuario) {
            //alert('usuario registrado');
        }
    }, [history, usuario]);

    const onFinish = async values => {

        setCargandoLogin(true);

        if(values.password.length < 6){
          values.password = values.password.padStart(6, "as");
      }
      values.correo = values.correo.toLowerCase();

      const { correo, password } = values;

      

      app.obtener_webinars_usuario(nombre_webinar+'/usuarios','correo', correo, function(campo){

        console.log('existe');
        console.log(campo);

          if(campo){
                  //setCorreoRecuperar(correo);

                  setCargandoLogin(false);

      const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};

          app.app
          .auth()
          .signInWithEmailAndPassword(correo, password.split('').map( letra => acentos[letra] || letra).join('').toString().toLowerCase())
          .then(result => {
              
              mensaje('success', 'Bienvenido', '');
              onResponse();
              setCargandoLogin(false);
          })
          .catch(error => {
              app.app
                  .auth()
                  .createUserWithEmailAndPassword(correo, password.split('').map( letra => acentos[letra] || letra).join('').toString().toLowerCase())
                  .then(result => {
                      //app.app.auth().signOut();
                  })
                  .catch(error => {
                      
                    mensaje('error','Correo y/o apellido no encontrado.','');

                      /*app.app.auth().signInAnonymously().then(() => {
                          console.log('los camios auth');
                          
                      }).catch(function(error) {
                          // Handle Errors here.
                          var errorCode = error.code;
                          var errorMessage = error.message;
                          console.log(error);
                          // ...
                      });*/
                  });
                  setCargandoLogin(false);
          });
          }else{
              mensaje('error','Usuario no registrado','');
              setCargandoLogin(false);
          }

          setCargandoLogin(false);
          

      });


    };


    useEffect(() => {
        

        recaptcha_phone = window.recaptchaVerifier = new app.app.auth.RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': function(response) {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              //ref_form_login.current.submit();
            }
          });;
    },[]);

    const colocarCodigo = () => {
        setCargandoLogin(true);
        console.log(ref_codigo.current.state.value);

        function_recaptcha.confirm(ref_codigo.current.state.value).then(function (result) {
            // User signed in successfully.
            var user = result.user;
            onResponse();
            setCargandoLogin(false);
            // ...
          }).catch(function (error) {
            // User couldn't sign in (bad verification code?)
            // ...
            mensaje('error','Código incorrecto');
            setCargandoLogin(false);
          });
          

      }

    return (
        <Row className="contenedor_registro">
            <Col span={24}>
            <Spin spinning={cargando_login}>
            {!ingresar_codigo ?
            (
            
                    
                    <Form className="login-form" 
                    onFinish={onFinish} 
                    ref={ref_form_login}
                    layout="vertical"
                    initialValues={{
                        prefix: '+52',
                      }} >
                        
                        <Form.Item
                                name="correo"
                                label={<span style={{ color:'#1141aa'}}>Correo electrónico</span>}
                                rules={[
                                    {
                                      type:'email',
                                      required: true,
                                      message: 'Ingresa tu correo usuario!',
                                    },
                                ]}
                                onChange= { (e)=> { setCorreoRecuperar(e.target.value); }}
                            >
                                <Input style={{backgroundColor:'#083280'}} />
                            </Form.Item>
                        
                            <Form.Item
                                name="password"
                                label={<span style={{ color:'#1141aa'}}>Apellido</span>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingresa tu apellido!',
                                    },
                                ]}
                                onChange= { (e)=> { setCorreoRecuperar(e.target.value); }}
                            >
                                <Input style={{backgroundColor:'#083280'}} />
                            </Form.Item>

                        {error ? <Form.Item><Errores mensaje={error} /></Form.Item> : null}
                        <Form.Item>
                        <Row>
                                
                                <Col span={24} style={{textAlign:'center'}}>
                                <br />

                                    <Button id="sign-in-button" style={{ border: 'none'}} htmlType="submit"><img style={{ width: '200px'}} src={entrar}/></Button>
                                    
                                </Col>
                            </Row>
                            <Row>
                                
                                <Col span={24} style={{textAlign:'center'}}>
                                    
                                <br />
                            <p style={{ color: '#112f6d', fontSize:'16px'}}>¿No tienes cuenta?</p>
                            </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{textAlign:'center'}}>
                                <Link to="/registro"><img style={{ width: '200px'}} src={registrar}/></Link>
                                    
                                </Col>
                            </Row>
                            
                        </Form.Item>

                    </Form>
            
            )
            :
            (
                <Row>
                    <Col span={0}>
                <p style={{textAlign:'center', color:'#fe633f'}}><br />Ingresa el código que llego a tu teléfono</p>
            </Col>
            <Col span={0}>
                <Input style={{width:'100%'}} ref={ref_codigo} />
            </Col>
            <Col span={0}>
                <Button className="boton_recibir_codigo" style={{width:'100%'}} onClick={colocarCodigo}>Verificar</Button>
            </Col>
                </Row>
            )
            }
</Spin>
</Col>
            

           
        
        </Row>

    );
}

export default Login;
