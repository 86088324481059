import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { notification } from 'antd';
import Firebase from '../../utils/firebaseConfig';

const app = new Firebase();

const nombre_webinar = 'premio-2023';

const mensaje = (tipo,titulo, texto) =>{

    notification[tipo]({
      message: titulo,
      description: texto,
      duration: null,
    });
  
  }

const MandarMensaje = () => {

    const location = useLocation();

    useEffect(()=>{
        app.obtener_objetos(nombre_webinar+'/configuracion_mensajes',true, (data)=>{
            if(data[0].mensaje_activar !== 0){
                if(location.pathname !== '/registro' && location.pathname !== '/agenda' && location.pathname !== '/admin-preguntas'){
                    mensaje('success',data[0].titulo,data[0].mensaje);
                }
            }
        });

    },[]);

    return(
        <>
        </>
    );

}

export default MandarMensaje;