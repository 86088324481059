import React, { useEffect, useContext ,useState} from "react";
import { Row, Col, notification, Modal} from "antd";
import { Auth } from "../../utils/AuthContext";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Firebase from '../../utils/firebaseConfig';
import '../../assets/css/actividades.css';
import conin_inicial from '../../assets/images/premio_2021/Estado05.gif';
import conin_hablar from '../../assets/images/premio_2021/Estado03.gif';
import audio_felicitacion from '../../assets/images/premio_2022/conin_audios/4_Retorno_a_transmision_digital_de_Conferencia.mp3';


let audio;
const nombre_evento = 'premio-2023';
const app = new Firebase();

const Felicitacion =({history}) =>{
    const [video_play, setVideoPlay] = useState('');
    const [estados_conin, setEstadosConin] = useState(0);
    const [play, setPlay] = useState(false);
    const [audios, setAudio] = useState('');
    const [mostrar_conin, setMostrarConin] = useState(true);
    const [final, setFinal] = useState(false);
    const [etapa, setEtapa] = useState(0);
    

    const Hablar = () => {
        setPlay(true);
        audio = new Audio(audios);
        audio.addEventListener('ended', () => {setEstadosConin(2); setPlay(false);} );
        audio.play();
    }
    useEffect(()=>{
        if(play){
            setEstadosConin(1)
        }
    },[play]);
    useEffect(()=>{
  switch(estados_conin){
      case 0: setAudio(audio_felicitacion); break;
      default: setAudio(''); break;
  }
},[estados_conin]);
useEffect(() => {
        app.obtener_objetos(nombre_evento + '/configuracion_flujo', true, (data) => {
            setEtapa(data[0].etapa);
        });
    
}, [etapa]);
useEffect(() => {
    if (etapa !== 5) {
        setMostrarConin(true);
    } else {

        setEstadosConin(0);
        setMostrarConin(false);
        setFinal(true);
    }

}, [etapa]);

    console.log(useContext(Auth));

    const videos = [
        {nombre:'CIRCUITO EXTERIOR MEXIQUENSE',url:'https://www.youtube.com/embed/cMewxDm1ybQ?rel=0&autoplay=1;'},
        {nombre:'FREIGHTLINER',url:'https://www.youtube.com/embed/NYgbpLpqQug?rel=0&autoplay=1;'},
        {nombre:'HINO',url:'https://www.youtube.com/embed/toynhSR-2-g?rel=0&autoplay=1;'},
        {nombre:'INTERNATIONAL',url:'https://www.youtube.com/embed/JBOgAQTI1L4?rel=0&autoplay=1;'},
        {nombre:'ISUZU',url:'https://www.youtube.com/embed/6vde67AdLFk?rel=0&autoplay=1;'},
        {nombre:'SCANIA',url:'https://www.youtube.com/embed/X2-mENIi12U?rel=0&autoplay=1;'},
        {nombre:'VOLKSWAGEN',url:'https://www.youtube.com/embed/mNqA2foG1Po?rel=0&autoplay=1;'},
        {nombre:'KENWORTH',url:'https://www.youtube.com/embed/YP3PnCFdOXg?rel=0&autoplay=1;'},
    ]

    let videos_select = videos.map((seccion) => {
        return(
            <>
             
            <Col xs={{span: 12, offset:0}} md={{span:7, offset:0}} >
          <div className="contenedor_video_conferencia">
          <iframe src={"https://www.youtube.com/embed/"+seccion.url.substring(30,41)+"?rel=0;autoplay=1;"} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <p className="nombre_video">{seccion.nombre}</p>
        </Col>
        </> 
        
        );
    }
    );
    return (
        <>
        <div className="contenedor_back">
            <Row align="">
                <Col span={20} offset={2}>
                    <br /><br />
                    <p className="texto_elige">FELICITACIONES</p>
                    <Row justify="space-around">
                    {videos_select}
                    </Row>

                    <br /><br />
                    
                </Col>
            </Row>
            <Modal
            style={{ backgroundColor: 'rgba(0,0,0,0)', top: '0px'}}
            title={null}
            footer={null}
            closable={null}
            visible = { video_play !== ''}
            onCancel = { () => { setVideoPlay('');} }
            bodyStyle = {{ backgroundColor: 'rgba(0,0,0,0)'}}
            width={'90%'}
            className="modal_videos"
        >
            <Row >
                
            
                <Col span={20} offset={2}>
                <div className="contenedor_video" style={{ marginTop: '15px'}}>
                    <br /><br /><br />
                    <iframe className="video" src={video_play} frameborder="0" allow="accelerometer; clipboardwrite; encryptedmedia; gyroscope; pictureinpicture" allowfullscreen></iframe>
                </div>
                </Col>
                <Col span={2} offset={0}>
                <p className="close_modal_x" onClick= { () => { setVideoPlay('');} } >x</p>
            </Col>
            </Row>
        </Modal>
        </div>
        
        </>
        
    );

}

export default Felicitacion;

