import React from 'react';
import { Row, Col } from 'antd';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img_freighliner from '../../assets/images/premio_2023/patrocinadores/freighliner.jpg';
import img_hino from '../../assets/images/premio_2023/patrocinadores/hino.jpg';
import img_international from '../../assets/images/premio_2023/patrocinadores/international.jpg';
import img_isuzu from '../../assets/images/premio_2023/patrocinadores/isuzu.jpg';
import img_kenworth from '../../assets/images/premio_2023/patrocinadores/kenworth.jpg';
import img_scania from '../../assets/images/premio_2023/patrocinadores/scania.jpg';
import img_vw_man from '../../assets/images/premio_2023/patrocinadores/vw_man.jpg';


let logos = [
    { index: 0, img_src: img_freighliner, nombre: '' },
    { index: 1, img_src: img_hino, nombre: '' },
    { index: 2, img_src: img_international, nombre: '' },
    { index: 3, img_src: img_isuzu, nombre: '' },
    { index: 4, img_src: img_kenworth, nombre: '' },
    { index: 5, img_src: img_scania, nombre: '' },
    { index: 6, img_src: img_vw_man, nombre: '' },
]

const CarouselPatrocinadores = (params) => {

    const ver_ponentes = logos.map(logo => {
        return(
          <Col span={3}>
            <img src={logo.img_src} alt={logo.nombre} className="img_logo" style={{ width:'100%'}}  />
          </Col>
        )
    });

    return (
      <Row justify="space-around">
        <Col span={24} offset={0}><br />
          <p style={{color:'#097ff6', fontSize:'24px'}}>
            <strong>Agradecemos a nuestros patrocinadores</strong>
          </p>
        </Col>
        {ver_ponentes}
      </Row>
    );

};

export default CarouselPatrocinadores;