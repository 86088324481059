import React, { useEffect, useContext ,useState} from "react";
import { Row, Col, notification, Modal} from "antd";
import { Auth } from "../../utils/AuthContext";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Firebase from '../../utils/firebaseConfig';


import cerrar_img from '../../assets/images/sitio/actividades/boton_x.png';

import almex from '../../assets/images/premio_2021/LOGOS/logo-almex.png';
import alpura from '../../assets/images/premio_2021/LOGOS/logo-alpura.png';
import barcel from '../../assets/images/premio_2021/LOGOS/logo-barcel.png';
import bimbo from '../../assets/images/premio_2021/LOGOS/logo-bimbo.png';
import concretos from '../../assets/images/premio_2021/LOGOS/logo-cemex concretos.png';
import cemex from '../../assets/images/premio_2021/LOGOS/logo-cemex.png';
import femsa from '../../assets/images/premio_2021/LOGOS/logo-cocacola femsa.png';
import munguia from '../../assets/images/premio_2021/LOGOS/logo-duarte munguia.png';
import esgari from '../../assets/images/premio_2021/LOGOS/logo-esgari.png';
import estafeta from '../../assets/images/premio_2021/LOGOS/logo-estafeta.png';
import fabricadejabon from '../../assets/images/premio_2021/LOGOS/logo-fabricadejabon.png';
import grupomodelo from '../../assets/images/premio_2021/LOGOS/logo-grupomodelo.png';
import hesa from '../../assets/images/premio_2021/LOGOS/logo-hesa.png';
import ilhuilcamina from '../../assets/images/premio_2021/LOGOS/logo-ilhuilcamina.png';
import jumex from '../../assets/images/premio_2021/LOGOS/logo-jumex.png';
import transportes from '../../assets/images/premio_2021/LOGOS/logo-lala transportes.png';
import lala from '../../assets/images/premio_2021/LOGOS/logo-lala.png';
import norycaribe from '../../assets/images/premio_2021/LOGOS/logo-norycaribe.png';
import palaciodehierropng from '../../assets/images/premio_2021/LOGOS/logo-palaciodehierropng.png';
import paquetexpress from '../../assets/images/premio_2021/LOGOS/logo-paquetexpress.png';
import pilot from '../../assets/images/premio_2021/LOGOS/logo-pilot.png';
import ricolino from '../../assets/images/premio_2021/LOGOS/logo-ricolino.png';
import sigma from '../../assets/images/premio_2021/LOGOS/logo-sigma.png';
import solistica from '../../assets/images/premio_2021/LOGOS/logo-solistica.png';
import tm from '../../assets/images/premio_2021/LOGOS/logo-tm.png';
import torres from '../../assets/images/premio_2021/LOGOS/logo-torres.png';
import trate from '../../assets/images/premio_2021/LOGOS/logo-trate.png';
import zonte from '../../assets/images/premio_2021/LOGOS/logo-zonte.png';

const nombre_evento = 'premio-2023';

const app = new Firebase();

let key = '';
const success = () => {
    mensaje('success','Bienvenido','');
  };

  const mensaje = (tipo,titulo, texto) =>{

    notification[tipo]({
      message: titulo,
      description: texto,
    });
  
  }

const ImagesGaleria =({history}) =>{


    const location = useLocation();
    const { usuario } = useContext(Auth);
    console.log(useContext(Auth));
    const [empresa, setEmpresa] = useState('');

    const [imagen_pop, setImagenPop] = useState('');

    const [ver_logo, setVerLogo] = useState('');
    console.log();

    useEffect(() => {

        console.log(usuario);
        
        if (!usuario) {
            //mensaje('warning','Advertencia','Para entrar a esta sección necesitas haber iniciado sesión.');
            //setSinLogin(true);
            window.location.href="/registro";
        }else{
            
            switch(location.pathname){//datos.empresa
                case '/galeria/almex': setEmpresa('almex'); setVerLogo(almex); break;
                case '/galeria/alpura': setEmpresa('alpura'); setVerLogo(alpura); break;
                case '/galeria/barcel': setEmpresa('barcel'); setVerLogo(barcel); break;
                case '/galeria/bimbo': setEmpresa('bimbo'); setVerLogo(bimbo); break;
                case '/galeria/concretos': setEmpresa('concretos'); setVerLogo(concretos); break;
                case '/galeria/cemex': setEmpresa('cemex'); setVerLogo(cemex); break;
                case '/galeria/femsa': setEmpresa('femsa'); setVerLogo(femsa); break;
                case '/galeria/munguia': setEmpresa('munguia'); setVerLogo(munguia); break;
                case '/galeria/esgari': setEmpresa('esgari'); setVerLogo(esgari); break;
                case '/galeria/estafeta': setEmpresa('estafeta'); setVerLogo(estafeta); break;
                case '/galeria/fabrica-de-jabon': setEmpresa('fabricadejabon'); setVerLogo(fabricadejabon); break;
                case '/galeria/grupomodelo': setEmpresa('grupomodelo'); setVerLogo(grupomodelo); break;
                case '/galeria/hesa': setEmpresa('hesa'); setVerLogo(hesa); break;
                case '/galeria/ilhuilcamina': setEmpresa('ilhuilcamina'); setVerLogo(ilhuilcamina); break;
                case '/galeria/jumex': setEmpresa('jumex'); setVerLogo(jumex); break;
                case '/galeria/transportes': setEmpresa('transportes'); setVerLogo(transportes); break;
                case '/galeria/lala': setEmpresa('lala'); setVerLogo(lala); break;
                case '/galeria/norycaribe': setEmpresa('norycaribe'); setVerLogo(norycaribe); break;
                case '/galeria/palaciodehierropng': setEmpresa('palaciodehierropng'); setVerLogo(palaciodehierropng); break;
                case '/galeria/paquetexpress': setEmpresa('paquetexpress'); setVerLogo(paquetexpress); break;
                case '/galeria/pilot': setEmpresa('pilot'); setVerLogo(pilot); break;
                case '/galeria/ricolino': setEmpresa('ricolino'); setVerLogo(ricolino); break;
                case '/galeria/sigma': setEmpresa('sigma'); setVerLogo(sigma); break;
                case '/galeria/solistica': setEmpresa('solistica'); setVerLogo(solistica); break;
                case '/galeria/tm': setEmpresa('tm'); setVerLogo(tm); break;
                case '/galeria/torres': setEmpresa('torres'); setVerLogo(torres); break;
                case '/galeria/trate': setEmpresa('trate'); setVerLogo(trate); break;
                case '/galeria/zonte': setEmpresa('zonte'); setVerLogo(zonte); break;
                
                default: window.location.href='/galeria'; break;
            }
        }
        
    }, [history, usuario]);

    const imagenes = [
        {empresa:'almex', imagenes: [{img:almex},{img:almex},{img:almex},{img:almex}]},
        {empresa:'alpura', imagenes: [{img:alpura},{img:alpura},{img:alpura},{img:alpura}]},
    ]


    console.log('empresa galeria', empresa);

    let ver_imagenes = imagenes.filter((video) => {
        if(empresa ===  video.empresa){
            return true; 
        }else{
            return false;
        }
    }).map((item) => {
        return(
            <Row>
                {item.imagenes.map((ima) => {
                    return(
                        <Col span={8} className="pointer_ver" onClick={() => setImagenPop(ima.img)} >
                            <img src={ima.img} alt={item.empresa} className="img_100"/>
                        </Col>
                    )
                })}
            </Row>
        )
    })

    return (

        
        <div className="contenedor_back">
            <Row align="">
                <Col xs={{offset:10,span:4}}>
                    <img src={ver_logo} className="img_100" />
                </Col>
                
            
        <Col xs={{span:23, offset:0}} lg={{span:4, offset:20}} style={{textAlign:'right', padding:'10px 30px 10px 0px'}}>
                    <Link to="/galeria" className="btn_regresar">Regresar</Link>
                </Col>
                <Col xs={{offset:4, span:16}}>
                <div className="contenedor_video_conferencia">
                    <iframe src="https://player.vimeo.com/video/635579152?h=aa97757217" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <br /><br /><br /><br />
                    {/*ver_imagenes*/}
                </Col>
            </Row>
            <Modal
            style={{ backgroundColor: 'rgba(0,0,0,0)', top: '0px'}}
            title={null}
            footer={null}
            closable={null}
            visible = { imagen_pop !== ''}
            onCancel = { () => { setImagenPop('');} }
            bodyStyle = {{ backgroundColor: 'rgba(0,0,0,0)'}}
            width={'90%'}
            className="modal_videos"
        >
            <Row >
                
            
                <Col span={20} offset={2}>
                <div className="contenedor_video" style={{ marginTop: '15px'}}>
                    <br /><br /><br />
                    <img className="img_100" src={imagen_pop} />
                </div>
                </Col>
                <Col span={2} offset={0}>
                <p className="close_modal_x" onClick= { () => { setImagenPop('');} } ><img src={cerrar_img} className="close_modal_x_img"/></p>
            </Col>
            </Row>
        </Modal>
        </div>
        
    );

}

export default ImagesGaleria;

