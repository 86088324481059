import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import '../../assets/css/footer.css';

import logo_usuarios from '../../assets/images/premio_2022/layout/pnsv_web_LogoUsuarios.png';
import logo_pnsv from '../../assets/images/premio_2023/logo_pnsv_vertical.png';
import img_frase from '../../assets/images/premio_2022/layout/pnsv_web_FraseFooter.png';

import rede_facebook from '../../assets/images/premio_2022/layout/iconos/pnsv_web_facebook.png';
import rede_twitter from '../../assets/images/premio_2022/layout/iconos/pnsv_web_twitter.png';
import rede_insta from '../../assets/images/premio_2022/layout/iconos/pnsv_web_instagram.png';
import rede_ytb from '../../assets/images/premio_2022/layout/iconos/pnsv_web_youtube.png';
import rede_linkedin from '../../assets/images/premio_2022/layout/iconos/pnsv_web_linkedin.png';

const Footer = () => {

  return (
    <>

      <Row align="middle" className="contenedor_footer">
        <Col span={20} offset={2}>
          <br />
          <Row align="middle">
            <Col span={4}>
              <img src="https://www.antp.org.mx/nd_img/header/antp_LogoMenuBlanco.png" style={{ width: '70%' }} />
            </Col>
            <Col span={16}>
              <img src={img_frase} style={{ width: '90%' }} />
            </Col>
            <Col span={4}>
              <img src={logo_pnsv} style={{ width: '70%' }} />
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 12, offset: 5 }} md={{ span: 12, offset: 6 }}>
          <br />
          <Row justify="center" align="middle">
            <Col span={14}>
              <p className='titulo_footer'>Síguenos en nuestras Redes Sociales @ANTPMéxico</p>
            </Col>
            <Col xs={{ span: 2, offset: 0 }}>
              <a href="https://es-la.facebook.com/antpmx/" target="_blank"><img src={rede_facebook} className="redes" /></a>
            </Col>
            <Col xs={{ span: 2, offset: 0 }}>
              <a href="https://twitter.com/antpmexico?lang=es" target="_blank"><img src={rede_twitter} className="redes" /></a>
            </Col>
            <Col xs={{ span: 2, offset: 0 }}>
              <a href="https://www.youtube.com/channel/UCQceWYoxh0kiHnQUhKttUTA" target="_blank"><img src={rede_ytb} className="redes" /></a>
            </Col>
            <Col xs={{ span: 2, offset: 0 }}>
              <a href="https://www.instagram.com/antp_mx/" target="_blank"><img src={rede_insta} className="redes" /></a>
            </Col>
            <Col xs={{ span: 2, offset: 0 }}>
              <a href="https://mx.linkedin.com/company/antp" target="_blank"><img src={rede_linkedin} className="redes" /></a>
            </Col>
          </Row>
        </Col>
        <Col span={20} offset={2}>
          <hr style={{backgroundColor:'#ffffff', height:'1px', border:'none', width:'100%'}} />
          
          <p className='sub_footer'>Asociación Nacional de Transporte Privado © 2023</p>
        </Col>
      </Row>
    </>
  );
}

export default Footer;