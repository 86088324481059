import React, { useEffect, useContext ,useState} from "react";
import { Row, Col, notification, Modal} from "antd";
import { Auth } from "../../utils/AuthContext";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Firebase from '../../utils/firebaseConfig';
import '../../assets/css/actividades.css';
import videos from './videos.json';

const Testimonios =(props) =>{
    const {empresa, datos} = props;
    const [video_play, setVideoPlay] = useState('');
    const [estados_conin, setEstadosConin] = useState(0);
    const [play, setPlay] = useState(false);
    console.log('empresa-testimonio', videos)
    

    let ver_empresa = videos.filter((item) =>
    {
      if(item.empresaf === empresa){
        return true;
    }else{
        return false;
    }
    })
    let videos_select = ver_empresa.map((seccion) => {
        return(
            <>
            <Col xs={{span: 12, offset:0}} md={{span:7, offset:0}} >
          <div className="contenedor_video_conferencia">
          <iframe src={"https://www.youtube.com/embed/"+seccion.url.substring(32,43)+"?rel=0;autoplay=1;"} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <p className="nombre_video">{seccion.nombre}</p>
         
        </Col>
        </> 
        
        );
    }
    );
    return (
        <>
        <div className="">
            <Row align="">
                
                <Row justify="space-around">
                {videos_select}
                </Row>
            </Row>
            
        </div>
        
        </>
        
    );

}

export default Testimonios;

