import React, { useContext, useState, useEffect } from 'react';
import {Link, useLocation } from 'react-router-dom';
import { Row, Col, Modal, notification } from 'antd';
import '../../assets/css/menu.css';
import Firebase from '../../utils/firebaseConfig';


const app = new Firebase();
const nombre_evento = 'premio-2023';

const Menu = () => {

    const location = useLocation();
    const [etapa, setEtapa] = useState(0);

    const secciones = [
        {url: '/', seccion:'Bienvenida', seccion_movil: 'Bienvenida', etapa:0},
        {url: '/auditorio', seccion:'Conferencia Motivacional', seccion_movil: 'Conferencia', etapa:1},
        {url: '/felicitacion-conductor', seccion:'Felicitación a Conductor', seccion_movil: 'Felicitación', etapa:2},
        //{url: '/expo', seccion:'Expo', seccion_movil: 'Expo', etapa:3},
        {url: '/despedida', seccion:'Sala de Premiación', seccion_movil: 'Sala', etapa:4}
    ];

    useEffect(()=>{
        app.obtener_objetos(nombre_evento+'/configuracion_flujo',true,(data) => {
            setEtapa(data[0].etapa);
          });
    },[]);


    const secciones_grande = secciones.map(seccion => {
            return(
                <Col xs={{span:0}} lg={{span:4, offset:0}} className="contenedor_seccion_menu">
                    { etapa === 5 ?
                        <Link to={ seccion.url}>
                    <p className={location.pathname === seccion.url || (location.pathname.includes(seccion.url) && seccion.url !== '/') ? 'seccion_inicio_sel_menu' : 'seccion_menu'}>
                        {seccion.seccion}
                    </p>
                    </Link>
                    :
                    <p className={etapa === seccion.etapa ? 'seccion_inicio_sel_menu' : 'seccion_menu'}>
                        {seccion.seccion}
                    </p>
                    }
                    
                </Col>
            );
    });

    const secciones_movil = secciones.filter(seccion => {
        if(location.pathname === seccion.url || (location.pathname.includes(seccion.url) && seccion.url !== '/')){
            return true;
        }else{
            return false;
        }
    }).map(seccion => {

            return (
                <Col xs={{span:16, offset:4}} lg={{span:0}}className="contenedor_seccion_menu">
                    <p className={location.pathname === seccion.url || (location.pathname.includes(seccion.url) && seccion.url !== '/')? 'seccion_inicio_sel_menu_movil' : 'seccion_inicio_menu'}>
                        {seccion.seccion}
                    </p>
                </Col>
            );
        

    });

    return(
        <Row align="middle" className="contenedor_menu back_azul">
            <Col span={24}>
                <br />
            </Col>
            <Col xs={{span:0, offset:0}} lg={{span:2, offset:0}}></Col>
           { secciones_grande }
           { secciones_movil }
           <Col span={24}>
                <br />
            </Col>
        </Row>
    );

}

export default Menu;
