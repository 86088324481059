import React, { useEffect, useState, useContext } from 'react';
import { Row, Col } from 'antd';
import { Auth } from '../../utils/AuthContext';
import Firebase from '../../utils/firebaseConfig';
import '../../assets/css/conferencia_empresa.css';


import almex from '../../assets/images/premio_2023/empresa_diplomas/alemex.png';
import barcel from '../../assets/images/premio_2023/empresa_diplomas/barcel.png';
import bepensa from '../../assets/images/premio_2023/empresa_diplomas/bepensa.png';
import bimbo from '../../assets/images/premio_2023/empresa_diplomas/bimbo.png';
import cemex from '../../assets/images/premio_2023/empresa_diplomas/cemex.png';
import concretos_cemex from '../../assets/images/premio_2023/empresa_diplomas/concretos_cemex.png';
import fabricadejaboneslacorona from '../../assets/images/premio_2023/empresa_diplomas/fabricadejaboneslacorona.png';
import hesa from '../../assets/images/premio_2023/empresa_diplomas/hesa.png';
import jumex from '../../assets/images/premio_2023/empresa_diplomas/jumex.png';
import lala_1 from '../../assets/images/premio_2023/empresa_diplomas/lala_d_primaria.png';
import lala_2 from '../../assets/images/premio_2023/empresa_diplomas/lala_d_secundaria.png';
import linde from '../../assets/images/premio_2023/empresa_diplomas/linde.png';
import marva from '../../assets/images/premio_2023/empresa_diplomas/marva.png';
import palaciodehierro from '../../assets/images/premio_2023/empresa_diplomas/palaciodehierro.png';
import paquetexpress from '../../assets/images/premio_2023/empresa_diplomas/paquetexpress.png';
import pilot from '../../assets/images/premio_2023/empresa_diplomas/pilot.png';
import sigma from '../../assets/images/premio_2023/empresa_diplomas/sigma.png';
import solistica from '../../assets/images/premio_2023/empresa_diplomas/solistica.png';
import tdr from '../../assets/images/premio_2023/empresa_diplomas/tdr.png';
import ttt from '../../assets/images/premio_2023/empresa_diplomas/torres-transportes-terrestres.png';
import transcooler from '../../assets/images/premio_2023/empresa_diplomas/transcooler.png';
import trate from '../../assets/images/premio_2023/empresa_diplomas/trate.png';
import zinergia from '../../assets/images/premio_2023/empresa_diplomas/zinergia.png';
import zonte from '../../assets/images/premio_2023/empresa_diplomas/zonte.png';
import VerVideos from './VerVideos';



const nombre_evento = 'premio-2023';
const app = new Firebase();


const VerEmpresas = () => {

    const { usuario, datos } = useContext(Auth);
    const [empresa, setEmpresa] = useState('');

    useEffect(() => {

        if (!usuario) {
            window.location.href = "/registro";
        } else {
            
        }

    }, [usuario]);

    const empresas = [

        //{ url: '/testimonios/almex', img_url: almex, alt: 'almex' },
        //{ url: '/testimonios/barcel', img_url: barcel, alt: 'barcel' },
        //{ url: '/testimonios/bepensa', img_url: bepensa, alt: 'bepensa' },
        //{ url: '/testimonios/bimbo', img_url: bimbo, alt: 'bimbo' },
        //{ url: '/testimonios/cemex', img_url: cemex, alt: 'cemex' },
        //{ url: '/testimonios/cemex-concretos', img_url: concretos_cemex, alt: 'cemex-concretos' },
        //{ url: '/testimonios/fabrica-de-jabon', img_url: fabricadejaboneslacorona, alt: 'fabrica-de-jabon' },
        //{ url: '/testimonios/hesa', img_url: hesa, alt: 'hesa' },
        //{ url: '/testimonios/jumex', img_url: jumex, alt: 'jumex' },
        { url: '/testimonios/lala-distribuidora-primaria', img_url: lala_1, alt: 'lala-distribuidora-primaria' },
        { url: '/testimonios/lala-distribuidora-secundaria', img_url: lala_2, alt: 'lala-distribuidora-secundaria' },
        //{ url: '/testimonios/linde', img_url: linde, alt: 'linde' },
        { url: '/testimonios/marva', img_url: marva, alt: 'marva' },
        { url: '/testimonios/palacio-de-hierro', img_url: palaciodehierro, alt: 'palacio-de-hierro' },
        { url: '/testimonios/paquetexpress', img_url: paquetexpress, alt: 'paquetexpress' },
        { url: '/testimonios/pilot', img_url: pilot, alt: 'pilot' },
        //{ url: '/testimonios/sigma', img_url: sigma, alt: 'sigma' },
        { url: '/testimonios/solistica', img_url: solistica, alt: 'solistica' },
        { url: '/testimonios/tdr', img_url: tdr, alt: 'tdr' },
        //{ url: '/testimonios/ttt', img_url: ttt, alt: 'ttt' },
        //{ url: '/testimonios/transcooler', img_url: transcooler, alt: 'transcooler' },
        //{ url: '/testimonios/trate', img_url: trate, alt: 'trate' },
        { url: '/testimonios/zinergia', img_url: zinergia, alt: 'zinergia' },
        //{ url: '/testimonios/zonte', img_url: zonte, alt: 'zonte' },
    ];

    let pintar_empresas = empresas.map((empresa, index) => {

        switch (index) {
            case 0: case 5: case 10: case 15: case 20: case 25:
                return (
                    <Col xs={{ span: 6, offset: 0 }} md={{ span: 4, offset: 2 }} style={{ position: 'relative' }}>

                        <div className="hover08 pointer_ver" onClick={() => { setEmpresa(empresa.alt); }}><figure><img src={empresa.img_url} className="img_empresa_conferencia " alt={empresa.alt} /></figure></div>

                    </Col>
                );
            break;
            default:
                return (
                    <Col xs={{ span: 6, offset: 0 }} md={{ span: 4, offset: 0 }} style={{ position: 'relative' }}>

                        <div className="hover08 pointer_ver" onClick={() => { setEmpresa(empresa.alt); }}><figure><img src={empresa.img_url} className="img_empresa_conferencia " alt={empresa.alt} /></figure></div>

                    </Col>
                );
                break;
        }

    });

    return (
        <>

{
                            empresa === '' ?
                                <Row align="middle" className="contenedor_back">
                                    <Col span={24} style={{ position: 'relative' }}>
                                        <br />
                                        <p className="texto_elige">SELECCIONA TU EMPRESA</p>
                                        <br /><br />
                                    </Col>

                                    {pintar_empresas}
                                    <Col xs={0} md={24}>
                                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                                    </Col>
                                </Row>
                                :
                                <VerVideos datos={datos} empresa_elegida={empresa} onResponse={() => { setEmpresa('') }} />
                        }
        </>
    );

}

export default VerEmpresas;