import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router";
import { Row, Col } from "antd";
import img_stand from "../../assets/images/evento/lobby_23oct.jpg";
import ImageMapper from "react-image-mapper";
import { useHistory } from "react-router-dom";
import { Auth } from "../../utils/AuthContext";
import axios from "axios";

const ExpoWeb = (props) => {
  const [widthp, setWidth] = useState(window.innerWidth);
  const [heightp, setHeight] = useState(window.innerHeight);
  const history = useHistory();
  const { usuario, datos } = useContext(Auth);

  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  const eventHandler = (req) => {
    if (usuario) {
      axios({
        method: "post",
        url: "https://admin.simposiumenergia.org.mx/api/log/event",
        data: {
          id: datos.id,
          stand: req.href.split("/").pop(),
          key: "click_stand",
        },
      });
    } else {
      if (props.idTracking) {
        console.log("hrefEvent:", req.href);
        console.log("idEvent:", props.idTracking);
        console.log("Pop", req.href.split("/").pop());

        axios({
          method: "post",
          url: "https://admin.simposiumenergia.org.mx/api/log/event",
          data: {
            id: props.idTracking,
            stand: req.href.split("/").pop(),
            key: "click_stand",
          },
        });
      } else {
        axios({
          method: "post",
          url: "https://admin.simposiumenergia.org.mx/api/log/event",
          data: {
            id: 0,
            stand: req.href.split("/").pop(),
            key: "click_stand",
          },
        });
      }
    }

    history.push(req.href);
  };

  const IMAGE_URL = img_stand;
  const AREAS_MAP = {
    name: "my-map",
    areas: [
      //{ name: "1", href:"", coords:[53,89,162,160,164,470,52,448], shape:"poly"},
      //{ name: "2", href:"", coords:[215,14,376,21,376,357,213,359], shape:"poly"},
      //{ name: "3", href:"", coords:[503,18,679,19,676,347,503,348], shape:"poly"},
      //{ name: "4", href:"", coords:[1273,18,1448,347], shape:"rect"},
      //{ name: "5", href:"", coords:[1539,16,1704,357], shape:"rect"},
      //{ name: "6", href:"", coords:[1755,157,1861,92,1861,445,1753,470], shape:"poly"},
      {
        name: "7",
        href: "/expo/platino/ance",
        coords: [50, 818, 153, 760, 155, 876, 111, 909, 55, 930],
        shape: "poly",
      },
      { name: "8", href:"/expo/platino/caname", coords:[281,817,386,761,386,875,343,907,284,929], shape:"poly"},
      { name: "9", href:"/expo/platino/casa-segura", coords:[457,761,559,817,557,927,488,905,454,876], shape:"poly"},
      {
        name: "10",
        href: "/expo/platino/condumex",
        coords: [673, 756, 771, 815, 773, 927, 703, 898, 671, 873],
        shape: "poly",
      },
      {
        name: "11",
        href: "/expo/platino/generac",
        coords: [866, 815, 966, 761, 968, 873, 930, 905, 864, 931],
        shape: "poly",
      },
      {
        name: "12",
        href: "/expo/platino/kobrex",
        coords: [1037, 759, 1139, 816, 1137, 930, 1073, 903, 1035, 876],
        shape: "poly",
      },
      {
        name: "13",
        href: "/expo/platino/mabe",
        coords: [1212, 815, 1314, 757, 1315, 874, 1280, 906, 1212, 931],
        shape: "poly",
      },
      {
        name: "14",
        href: "/expo/platino/mt",
        coords: [1387, 757, 1485, 815, 1482, 928, 1419, 906, 1380, 874],
        shape: "poly",
      },
      {
        name: "15",
        href: "/expo/platino/prolec",
        coords: [1556, 817, 1661, 761, 1663, 875, 1624, 907, 1561, 934],
        shape: "poly",
      },
      {
        name: "16",
        href: "/expo/platino/schneider",
        coords: [1760, 758, 1861, 815, 1856, 927, 1795, 907, 1755, 873],
        shape: "poly",
      },
      {
        name: "17",
        href: "/expo/oro/siemens",
        coords: [55, 948, 155, 1000, 157, 1115, 94, 1095, 52, 1065],
        shape: "poly",
      },
      {
        name: "18",
        href: "/expo/platino/viakon",
        coords: [279, 947, 382, 1003, 382, 1115, 316, 1093, 276, 1060],
        shape: "poly",
      },
      {
        name: "19",
        href: "/expo/oro/hitachi",
        coords: [445, 999, 547, 947, 550, 1057, 505, 1094, 445, 1118],
        shape: "poly",
      },
      {
        name: "20",
        href: "/expo/oro/signify",
        coords: [656, 998, 757, 943, 761, 1059, 717, 1093, 656, 1115],
        shape: "poly",
      },
      { name: "21", href:"/expo/oro/serintra", coords:[862,944,966,1002,964,1115,895,1090,859,1061], shape:"poly"},
      {
        name: "22",
        href: "/expo/oro/orto",
        coords: [1035, 1000, 1137, 946, 1139, 1058, 1102, 1093, 1035, 1117],
        shape: "poly",
      },
      {
        name: "23",
        href: "/expo/oro/voltran",
        coords: [1224, 948, 1326, 1000, 1322, 1115, 1264, 1092, 1222, 1059],
        shape: "poly",
      },
      { name: "24", href:"/expo/oro/revista-caname", coords:[1387,1003,1487,943,1490,1059,1449,1093,1387,1115], shape:"poly"},
      { name: "25", href:"/expo/oro/ledvance", coords:[1563,947,1663,1003,1663,1115,1590,1087,1561,1062], shape:"poly"},
      //{ name: "26", href:"", coords:[1758,999,1853,947,1856,1057,1811,1096,1758,1116], shape:"poly"},
      { name: "27", href:"/expo/media/conuee", coords:[52,1191,137,1142,143,1243,98,1271,53,1291], shape:"poly"},
      {
        name: "28",
        href: "/expo/media/fide",
        coords: [
          242, 1193, 327, 1147, 330, 1238, 294, 1274, 240, 1293, 240, 1276,
        ],
        shape: "poly",
      },
      {
        name: "29",
        href: "/expo/media/mexico-industry",
        coords: [399, 1144, 483, 1192, 481, 1293, 432, 1273, 394, 1246],
        shape: "poly",
      },
      {
        name: "30",
        href: "/expo/media/petroleo-energia",
        coords: [579, 1151, 669, 1202, 664, 1298, 608, 1280, 572, 1248],
        shape: "poly",
      },
      {
        name: "31",
        href: "/expo/media/mundo-ejecutivo",
        coords: [744, 1202, 830, 1151, 830, 1254, 791, 1283, 747, 1297],
        shape: "poly",
      },
      {
        name: "32",
        href: "/expo/media/energia-hoy",
        coords: [896, 1157, 981, 1194, 983, 1296, 918, 1271, 896, 1247],
        shape: "poly",
      },
      {
        name: "33",
        href: "/expo/media/greentology",
        coords: [1047, 1193, 1132, 1146, 1141, 1244, 1107, 1268, 1052, 1292],
        shape: "poly",
      },
      { name: "34", href:"/expo/media/mundo-sustentable", coords:[1197,1150,1281,1187,1278,1289,1222,1265,1190,1240], shape:"poly"},
      { name: "35", href:"/expo/media/usecim", coords:[1344,1187,1432,1142,1432,1240,1397,1271,1346,1287], shape:"poly"},
      {
        name: "36",
        href: "/expo/media/global-energy",
        coords: [1510, 1145, 1592, 1189, 1590, 1289, 1546, 1272, 1505, 1242],
        shape: "poly",
      },
      {
        name: "37",
        href: "/expo/media/pv-magazine",
        coords: [1631, 1191, 1722, 1145, 1722, 1242, 1687, 1271, 1634, 1289],
        shape: "poly",
      },
      { name: "38", href:"/expo/media/directorios-industriales", coords:[1799,1143,1887,1195,1885,1290,1821,1263,1794,1243], shape:"poly"},
    ],
  };
  return (
    <>
      <Row>
        <Col xs={24}>
          <ImageMapper
            src={IMAGE_URL}
            map={AREAS_MAP}
            width={widthp}
            imgWidth={1920}
            target="_blank"
            onClick={eventHandler}
          />
        </Col>
      </Row>
    </>
  );
};

export default ExpoWeb;
