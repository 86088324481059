import React, {useEffect, useState, useContext} from 'react';
import Firebase from '../../utils/firebaseConfig';
import { Auth } from "../../utils/AuthContext";

import conin_inicial from '../../assets/images/premio_2021/Estado05.gif';
import conin_hablar from '../../assets/images/premio_2021/Estado03.gif';
import conin_festejar from '../../assets/images/premio_2021/Estado04.gif';
import conin_final from '../../assets/images/premio_2021/Estado01.gif';
import conin_pensando from '../../assets/images/premio_2021/Estado02.gif';

import '../../assets/css/conin.css';

const nombre_evento = 'premio-2023';
const app = new Firebase();

//aparezca y desaparexza con variable de stado
//cambie de stado conin con var edo

const Conin = (props) => {

    const { datos, setConinPantalla, estados_conin, setEstadosConin } = useContext(Auth);
    const [reproducir_conin, setReproducirConin] = useState(false);
    const [audio, setAudio] = useState();
    const [etapa, setEtapa] = useState(-1);
    const [mostrar_conin, setMostrarConin] = useState(true);

    useEffect(()=>{
        app.obtener_objetos(nombre_evento+'/configuracion_flujo',true,(data) => {

        console.log('conin',data[0].etapa );
            setEtapa(data[0].etapa);
            switch(data[0].etapa){
                //case 0: setAudio(`https://conin.antp.org.mx/audios/bienvenida/${datos.perfil === 'Operador' ? 'operador' : 'invitado'}/${datos.audio}`); setEstadosConin(0); console.log('inicial'); break;
                case 0: setAudio('https://www.antp.org.mx/conin_audios/1_Recepcion_Operadores.mp3'); setEstadosConin(0); console.log('inicial');break;
                case 1: setAudio('https://conin.antp.org.mx/audios/conferencia/conferencia'); setEstadosConin(2); break;
                case 2: setAudio('https://conin.antp.org.mx/audios/premiacion/normal'); setEstadosConin(4); break;
                case 3: setAudio('https://conin.antp.org.mx/audios/expo/normal'); setEstadosConin(12); break;
                case 4: setAudio('https://conin.antp.org.mx/audios/agradecimiento/normal'); setEstadosConin(14); break;
                default: break;
            }
        });
    },[datos]);

    const Hablar = () => {
        setReproducirConin(true);
    }

    useEffect(()=>{
        if(reproducir_conin){
            setEstadosConin(estados_conin+1);
        }
    },[reproducir_conin]);

    useEffect(()=>{
        if(estados_conin == 1){}
        let audio_conin = new Audio(audio);
        console.log(estados_conin);
        audio_conin.addEventListener('ended', () => {
            setMostrarConin(false); setReproducirConin(false);
            console.log('termini', estados_conin);
            switch(estados_conin){
                case 1: setEstadosConin(-1); setConinPantalla(false); break;
                case 3: setEstadosConin(-1); setConinPantalla(false); break;
                case 5: setAudio('https://conin.antp.org.mx/audios/premiacion/'+datos.audio); setEstadosConin(6); break;
                case 7: setAudio('https://conin.antp.org.mx/audios/premiacion/'+datos.audio); setEstadosConin(8); break;
                case 9: setAudio('https://conin.antp.org.mx/audios/premiacion/'+datos.audio); setEstadosConin(10); break;
                case 11: setAudio('https://conin.antp.org.mx/audios/premiacion/'+datos.audio); setEstadosConin(11); break;
                case 13: setAudio('https://conin.antp.org.mx/audios/premiacion/'+datos.audio); setEstadosConin(13); break;
            }
        } );
        audio_conin.play();
        console.log('estaod conin', estados_conin);
    },[estados_conin]);


    return(
            mostrar_conin &&
                estados_conin === -1 ?
                    <></>
                :
                    <img src=
                        {(estados_conin === 0 || estados_conin === 2 || estados_conin === 6 || estados_conin === 8 || estados_conin === 10 || estados_conin === 12 || estados_conin === 14 ) ? 
                            conin_inicial 
                        : (estados_conin === 1 || estados_conin === 3 || estados_conin === 5 || estados_conin === 7 || estados_conin === 9 || estados_conin === 11 || estados_conin === 13 || estados_conin === 15 || estados_conin === 1) ? 
                            conin_hablar 
                        : (estados_conin === 41) ? 
                            conin_festejar 
                        : conin_final} className="conin" alt="Conin" 
                        onClick={()=>{if(estados_conin === 0 || estados_conin === 2 || estados_conin === 41 || estados_conin === 6 || estados_conin === 8 || estados_conin === 10 || estados_conin === 12 || estados_conin === 14) Hablar()}} 
                    />
    );
}

export default Conin;
