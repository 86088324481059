import React from 'react';
import { Row, Col } from 'antd';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img_alemex from '../../assets/images/premio_2023/empresa_diplomas/alemex.png';
import img_barcel from '../../assets/images/premio_2023/empresa_diplomas/barcel.png';
import img_bepensa from '../../assets/images/premio_2023/empresa_diplomas/bepensa.png';
import img_bimbo from '../../assets/images/premio_2023/empresa_diplomas/bimbo.png';
import img_cemex from '../../assets/images/premio_2023/empresa_diplomas/cemex.png';
import img_concretos_cemex from '../../assets/images/premio_2023/empresa_diplomas/concretos_cemex.png';
import img_fabricadejaboneslacorona from '../../assets/images/premio_2023/empresa_diplomas/fabricadejaboneslacorona.png';
import img_hesa from '../../assets/images/premio_2023/empresa_diplomas/hesa.png';
import img_jumex from '../../assets/images/premio_2023/empresa_diplomas/jumex.png';
import img_lala from '../../assets/images/premio_2023/empresa_diplomas/lala.png';
import img_linde from '../../assets/images/premio_2023/empresa_diplomas/linde.png';
import img_marva from '../../assets/images/premio_2023/empresa_diplomas/marva.png';
import img_palaciodehierro from '../../assets/images/premio_2023/empresa_diplomas/palaciodehierro.png';
import img_paquetexpress from '../../assets/images/premio_2023/empresa_diplomas/paquetexpress.png';
import img_pilot from '../../assets/images/premio_2023/empresa_diplomas/pilot.png';
import img_sigma from '../../assets/images/premio_2023/empresa_diplomas/sigma.png';
import img_solistica from '../../assets/images/premio_2023/empresa_diplomas/solistica.png';
import img_tdr from '../../assets/images/premio_2023/empresa_diplomas/tdr.png';
import img_transcooler from '../../assets/images/premio_2023/empresa_diplomas/transcooler.png';
import img_trate from '../../assets/images/premio_2023/empresa_diplomas/trate.png';
import img_zinergia from '../../assets/images/premio_2023/empresa_diplomas/zinergia.png';
import img_zonte from '../../assets/images/premio_2023/empresa_diplomas/zonte.png';


let logos = [
    { img_src: img_alemex, nombre: 'img_alemex' },
    { img_src: img_barcel, nombre: 'img_barcel' },
    { img_src: img_bepensa, nombre: 'img_bepensa' },
    { img_src: img_bimbo, nombre: 'img_bimbo' },
    { img_src: img_cemex, nombre: 'img_cemex' },
    { img_src: img_concretos_cemex, nombre: 'img_concretos_cemex' },
    { img_src: img_fabricadejaboneslacorona, nombre: 'img_fabricadejaboneslacorona' },
    { img_src: img_hesa, nombre: 'img_hesa' },
    { img_src: img_jumex, nombre: 'img_jumex' },
    { img_src: img_lala, nombre: 'img_lala' },
    { img_src: img_linde, nombre: 'img_linde' },
    { img_src: img_marva, nombre: 'img_marva' },
    { img_src: img_palaciodehierro, nombre: 'img_palaciodehierro' },
    { img_src: img_paquetexpress, nombre: 'img_paquetexpress' },
    { img_src: img_pilot, nombre: 'img_pilot' },
    { img_src: img_sigma, nombre: 'img_sigma' },
    { img_src: img_solistica, nombre: 'img_solistica' },
    { img_src: img_tdr, nombre: 'img_tdr' },
    { img_src: img_transcooler, nombre: 'img_transcooler' },
    { img_src: img_trate, nombre: 'img_trate' },
    { img_src: img_zinergia, nombre: 'img_zinergia' },
    { img_src: img_zonte, nombre: 'img_zonte' },
]

const CarouselEmpresas = (params) => {

    const ver_ponentes = logos.map(logo => {
        return(
          <div>
            <img src={logo.img_src} alt={logo.nombre} className="img_logo" style={{ width:'100%'}}  />
          </div>
        )
    });
    var settings = {
        dots: true,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: "linear",
      arrow : true
    };

    return (
      <Row>
        <Col span={24} offset={0}><br />
          <p style={{color:'#097ff6', fontSize:'28px'}}>
            <strong>Empresas ganadoras</strong>
          </p>

          <Slider {...settings}>
          {ver_ponentes}
        </Slider>
        </Col>
      </Row>
    );

};

export default CarouselEmpresas;