import React from 'react';
import { Row, Col } from 'antd';

import '../../assets/css/agenda_premio.css';

import logo_usuario_premio from '../../assets/images/registro/logo_PNSVXXI_registro.png';
import bienvenido from '../../assets/images/registro/bienvenidos_registro.png';
import Contador from '../evento/Contador';

const Agenda = () => {


    

    return(
        <Row justify="center">

            <Col span={24} className="contendor_banner">

                <p className="contenedor_imagenes">
                    <img src={logo_usuario_premio} className="img_logo_usuarios_agenda" />
                    <br /><br />
                    <img src={bienvenido} className="img_bienvenida_agenda" />
                </p>

                <p className="texto_agenda_banner">
                    
                    <br /><br />
                    <Contador dateTarget="12/06/2020 10:00:00 AM" />
                    <br /><br />
                    AGENDA
                </p>
            </Col>

            <Col xs={{span:22, offset:0}} md={{span:12, offset:0}}>
                <br />
                <p className="titulo_fecha">Lunes 7 de diciembre</p>
                <br />
                <Row className="">
                    <Col span={10}><p className="texto_tabla_hora">Hora</p></Col>
                    <Col span={14}><p className="texto_tabla_actividad"><strong>Actividad</strong></p></Col>
                </Row>
                <Row className="contenedor_body_tabla">
                    <Col span={10}><p className="texto_tabla_body_hora">Del 30 de octubre al 7 de diciembre</p></Col>
                    <Col span={14}><p className="texto_tabla_body_actividad">Registro de participantes </p></Col>
                </Row>
                <Row className="contenedor_body_tabla">
                    <Col span={10}><p className="texto_tabla_body_hora">8:50 – 9:05</p></Col>
                    <Col span={14}><p className="texto_tabla_body_actividad">Bienvenida Presidente/Director ANTP.</p></Col>
                </Row>
                <Row className="contenedor_body_tabla">
                    <Col span={10}><p className="texto_tabla_body_hora">9:05 – 10:00</p></Col>
                    <Col span={14}><p className="texto_tabla_body_actividad">Charla Motivacional.</p></Col>
                </Row>
                <Row className="contenedor_body_tabla">
                    <Col span={10}><p className="texto_tabla_body_hora">10:00 – 11:30</p></Col>
                    <Col span={14}><p className="texto_tabla_body_actividad">Entrega de reconocimientos a conductores </p></Col>
                </Row>
                <Row className="contenedor_body_tabla">
                    <Col span={10}><p className="texto_tabla_body_hora">11:00 – 12:00</p></Col>
                    <Col span={14}><p className="texto_tabla_body_actividad">Recorrido stands y actividades </p></Col>
                </Row>
                <Row className="contenedor_body_tabla">
                    <Col span={10}><p className="texto_tabla_body_hora">12:00 – 12:30</p></Col>
                    <Col span={14}>
                        <p className="texto_tabla_body_actividad">
                            <strong>Importancia de la seguridad vial en México </strong>
                            <br /><br />
                            <ul>
                                <li>
                                    Presidente/Director General <strong>ANTP</strong>
                                    </li>
                                <li>
                                    Luis Rodríguez Bucio<br />
                            Comandante de la Guardia Nacional <strong>GN</strong>
                            </li>
                                <li>
                                Carlos Alfonso Morán Moguel <br />
                            Subsecretario de Transportes de la <strong>SCT</strong>
                                </li>
                            </ul>
                            
                        </p>
                    </Col>
                </Row>
                <Row className="contenedor_body_tabla">
                    <Col span={10}><p className="texto_tabla_body_hora">12:30 – 13:30</p></Col>
                    <Col span={14}><p className="texto_tabla_body_actividad">Entrega de premiación a empresas </p></Col>
                </Row>
                <Row className="contenedor_body_tabla">
                    <Col span={10}><p className="texto_tabla_body_hora">13:30 – 15:00</p></Col>
                    <Col span={14}><p className="texto_tabla_body_actividad">Presentación Musical</p></Col>
                </Row>
                <br /><br />
            </Col>
            
        </Row>
    );
}

export default Agenda;
