import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Modal, Spin, message, Button, notification } from "antd";
import { Auth } from "../../utils/AuthContext";
import { Redirect, withRouter, useLocation } from "react-router";
import { Link } from "react-router-dom";
import Firebase from "../../utils/firebaseConfig";
import Live from "../conferencias/Live";
import Menu from "../master/Menu";

import LiveSinComentarios from "../conferencias/LiveSinComentarios";
import LiveEmpresa from "../conferencias/LiveEmpresa";
import Diplomas from "./Diplomas";

import img_primer_lugar from "../../assets/images/sitio/felicitaciones/pnsv_1erlugar.png";
import img_segundo_lugar from "../../assets/images/sitio/felicitaciones/pnsv_2dolugar.png";
import img_tercer_lugar from "../../assets/images/sitio/felicitaciones/pnsv_3erlugar.png";

import almex from '../../assets/images/premio_2023/empresa_diplomas/alemex.png';
import barcel from '../../assets/images/premio_2023/empresa_diplomas/barcel.png';
import bepensa from '../../assets/images/premio_2023/empresa_diplomas/bepensa.png';
import bimbo from '../../assets/images/premio_2023/empresa_diplomas/bimbo.png';
import cemex from '../../assets/images/premio_2023/empresa_diplomas/cemex.png';
import concretos_cemex from '../../assets/images/premio_2023/empresa_diplomas/concretos_cemex.png';
import fabricadejaboneslacorona from '../../assets/images/premio_2023/empresa_diplomas/fabricadejaboneslacorona.png';
import hesa from '../../assets/images/premio_2023/empresa_diplomas/hesa.png';
import jumex from '../../assets/images/premio_2023/empresa_diplomas/jumex.png';
import lala_1 from '../../assets/images/premio_2023/empresa_diplomas/lala_d_primaria.png';
import lala_2 from '../../assets/images/premio_2023/empresa_diplomas/lala_d_secundaria.png';
import linde from '../../assets/images/premio_2023/empresa_diplomas/linde.png';
import marva from '../../assets/images/premio_2023/empresa_diplomas/marva.png';
import palaciodehierro from '../../assets/images/premio_2023/empresa_diplomas/palaciodehierro.png';
import paquetexpress from '../../assets/images/premio_2023/empresa_diplomas/paquetexpress.png';
import pilot from '../../assets/images/premio_2023/empresa_diplomas/pilot.png';
import sigma from '../../assets/images/premio_2023/empresa_diplomas/sigma.png';
import solistica from '../../assets/images/premio_2023/empresa_diplomas/solistica.png';
import tdr from '../../assets/images/premio_2023/empresa_diplomas/tdr.png';
import ttt from '../../assets/images/premio_2023/empresa_diplomas/torres-transportes-terrestres.png';
import transcooler from '../../assets/images/premio_2023/empresa_diplomas/transcooler.png';
import trate from '../../assets/images/premio_2023/empresa_diplomas/trate.png';
import zinergia from '../../assets/images/premio_2023/empresa_diplomas/zinergia.png';
import zonte from '../../assets/images/premio_2023/empresa_diplomas/zonte.png';
import { LeftCircleOutlined } from "@ant-design/icons";

const nombre_evento = "premio-2023";

const app = new Firebase();

let key = "";
const success = () => {
  mensaje("success", "Bienvenido", "");
};

const mensaje = (tipo, titulo, texto) => {
  notification[tipo]({
    message: titulo,
    description: texto,
  });
};

const DemoEmpresa = ({
  history,
  empresa_elegida = undefined,
  onResponse,
  ocultarConin,
}) => {
  const location = useLocation();
  const { usuario, datos } = useContext(Auth);
  const [preguntas_ponente, setPreguntasPonente] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [sin_login, setSinLogin] = useState(false);
  const [youtube, setYoutube] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [activar_descarga, setActivarDescarga] = useState(true);
  const [mostrar_diplomas, setMostrarDiplomas] = useState(true);

  const [ver_primero, setVerPrimero] = useState(true);
  const [ver_segundo, setVerSegundo] = useState(true);
  const [ver_tercerp, setVerTercero] = useState(true);

  const [ver_salon, setVerSalon] = useState(false);

  const [ver_premiacion, setVerPremiacion] = useState("");
  const [ver_logo, setVerLogo] = useState("");
  console.log();

  useEffect(() => {
    if (localStorage.empresa_url !== undefined) {
      if (localStorage.empresa_url !== "") {
        setMostrarDiplomas(true);
      } else {
        setMostrarDiplomas(false);
      }
    }
  }, []);

  useEffect(() => {
    if (empresa !== "") {
      app.obtener_objetos(
        nombre_evento + "/preguntas-publico/chat",
        true,
        (data) => {
          setPreguntasPonente(data);
        }
      );
    }
  }, [empresa]);

  useEffect(() => {
    if (ver_premiacion !== "") {
      ocultarConin(6);
    }
  }, [ver_premiacion]);

  useEffect(() => {
    console.log(usuario);

    if (!usuario) {
      //mensaje('warning','Advertencia','Para entrar a esta sección necesitas haber iniciado sesión.');
      //setSinLogin(true);
      window.location.href = "/registro";
    } else {
      app.obtener_objetos(nombre_evento + "/activar_descarga", true, (data) => {
        setActivarDescarga(data[0].activar);
      });

      switch (
        empresa_elegida //datos.empresa
      ) {
        case '/felicitacion-conductor/almex':
setVerLogo(almex);
setEmpresa("almex");
break;
case '/felicitacion-conductor/barcel':
setVerLogo(barcel);
setEmpresa("barcel");
break;
case '/felicitacion-conductor/bepensa':
setVerLogo(bepensa);
setEmpresa("bepensa");
break;
case '/felicitacion-conductor/bimbo':
setVerLogo(bimbo);
setEmpresa("bimbo");
break;
case '/felicitacion-conductor/cemex':
setVerLogo(cemex);
setEmpresa("cemex");
break;
case '/felicitacion-conductor/cemex-concretos':
setVerLogo(concretos_cemex);
setEmpresa("cemex-concretos");
break;
case '/felicitacion-conductor/fabrica-de-jabon':
setVerLogo(fabricadejaboneslacorona);
setEmpresa("fabrica-de-jabon");
break;
case '/felicitacion-conductor/hesa':
setVerLogo(hesa);
setEmpresa("hesa");
break;
case '/felicitacion-conductor/jumex':
setVerLogo(jumex);
setEmpresa("jumex");
break;
case '/felicitacion-conductor/lala-distribuidora-primaria':
setVerLogo(lala_1);
setEmpresa("lala-distribuidora-primaria");
break;
case '/felicitacion-conductor/lala-distribuidora-secundaria':
setVerLogo(lala_2);
setEmpresa("lala-distribuidora-secundaria");
break;
case '/felicitacion-conductor/linde':
setVerLogo(linde);
setEmpresa("linde");
break;
case '/felicitacion-conductor/marva':
setVerLogo(marva);
setEmpresa("marva");
break;
case '/felicitacion-conductor/palacio-de-hierro':
  setEmpresa("palacio-de-hierro");
  setVerLogo(palaciodehierro);
break;
case '/felicitacion-conductor/paquetexpress':
setVerLogo(paquetexpress);
setEmpresa("paquetexpress");
break;
case '/felicitacion-conductor/pilot':
setVerLogo(pilot);
setEmpresa("pilot");
break;
case '/felicitacion-conductor/sigma':
setVerLogo(sigma);
setEmpresa("sigma");
break;
case '/felicitacion-conductor/solistica':
setVerLogo(solistica);
setEmpresa("solistica");
break;
case '/felicitacion-conductor/tdr':
setVerLogo(tdr);
setEmpresa("tdr");
break;
case '/felicitacion-conductor/ttt':
setVerLogo(ttt);
setEmpresa("ttt");
break;
case '/felicitacion-conductor/transcooler':
setVerLogo(transcooler);
setEmpresa("transcooler");
break;
case '/felicitacion-conductor/trate':
setVerLogo(trate);
setEmpresa("trate");
break;
case '/felicitacion-conductor/cinergia':
setVerLogo(zinergia);
setEmpresa("zinergia");
break;
case '/felicitacion-conductor/zonte':
setVerLogo(zonte);
setEmpresa("zonte");
break;

        default:
          if (empresa_elegida === undefined) {
            window.location.href = "/felicitacion-conductor";
          } else {
            setEmpresa(empresa_elegida);
          }
          break;
      }
    }

    switch (
      empresa_elegida //datos.empresa
    ) {
      case "almex":
setVerLogo(almex);
break;
case "barcel":
setVerLogo(barcel);
break;
case "bepensa":
setVerLogo(bepensa);
break;
case "bimbo":
setVerLogo(bimbo);
break;
case "cemex":
setVerLogo(cemex);
break;
case "cemex-concretos":
setVerLogo(concretos_cemex);
break;
case "fabrica-de-jabon":
setVerLogo(fabricadejaboneslacorona);
break;
case "hesa":
setVerLogo(hesa);
break;
case "jumex":
setVerLogo(jumex);
break;
case "lala-distribuidora-primaria":
setVerLogo(lala_1);
break;
case "lala-distribuidora-secundaria":
setVerLogo(lala_2);
break;
case "linde":
setVerLogo(linde);
break;
case "marva":
setVerLogo(marva);
break;
case "palacio-de-hierro":
setVerLogo(palaciodehierro);
break;
case "paquetexpress":
setVerLogo(paquetexpress);
break;
case "pilot":
setVerLogo(pilot);
break;
case "sigma":
setVerLogo(sigma);
break;
case "solistica":
setVerLogo(solistica);
break;
case "tdr":
setVerLogo(tdr);
break;
case "ttt":
setVerLogo(ttt);
break;
case "transcoolet":
setVerLogo(transcooler);
break;
case "trate":
setVerLogo(trate);
break;
case "zinergia":
setVerLogo(zinergia);
break;
case "zonte":
setVerLogo(zonte);
break;

      default:
        setVerLogo("");
        break;
    }
  }, [history, usuario]);

  const urls = [
    {
      empresa: "almex",
      lugar: "1er Lugar",//2do Lugar - 3er Lugar
      url: "https://www.youtube.com/embed/gJLBNMHdlWo?rel=0&autoplay=1;",
      },
      {
        empresa: "pilot",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/QfgMzJ6US7I?rel=0&autoplay=1;",
      },
      {
        empresa: "pilot",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/YDq1amURMs0?rel=0&autoplay=1;",
      },
      {
        empresa: "pilot",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/ymvESIysqOQ?rel=0&autoplay=1;",
      },
      {
        empresa: "zonte",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/Zxl7dzJ5l8Y?rel=0&autoplay=1;",
      },
      {
        empresa: "zonte",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/es997Gd2uAc?rel=0&autoplay=1;",
      },
      {
        empresa: "barcel",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/HH2SlUxEOLc?rel=0&autoplay=1;",
      },
      {
        empresa: "barcel",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/LurXwhCXO4c?rel=0&autoplay=1;",
      },
      {
        empresa: "barcel",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/mLw-KSByppQ?rel=0&autoplay=1;",
      },
      {
        empresa: "bimbo",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/umeEG5YSteA?rel=0&autoplay=1;",
      },
      {
        empresa: "bimbo",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/8I8KWpirNKc?rel=0&autoplay=1;",
      },
      {
        empresa: "bimbo",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/-tlYkDPWtQk?rel=0&autoplay=1;",
      },
      {
        empresa: "cemex-concretos",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/WqGFuxX-Rl4?rel=0&autoplay=1;",
      },
      {
        empresa: "cemex-concretos",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/8rVrLsMX3cg?rel=0&autoplay=1;",
      },
      {
        empresa: "cemex-concretos",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/jOaI7qbg0vE?rel=0&autoplay=1;",
      },
      {
        empresa: "cemex",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/udVpsc1wrac?rel=0&autoplay=1;",
      },
      {
        empresa: "cemex",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/QEg-fwNiRoQ?rel=0&autoplay=1;",
      },
      {
        empresa: "cemex",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/rwjhPlc8DEM?rel=0&autoplay=1;",
      },
      {
        empresa: "lala-distribuidora-primaria",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/SKALSi7on_Q?rel=0&autoplay=1;",
      },
      {
        empresa: "lala-distribuidora-primaria",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/1PJHNrJ42cM?rel=0&autoplay=1;",
      },
      {
        empresa: "lala-distribuidora-primaria",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/0eL5PXf_7Uk?rel=0&autoplay=1;",
      },
      {
        empresa: "lala-distribuidora-secundaria",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/45MH32lZIK0?rel=0&autoplay=1;",
      },
      {
        empresa: "lala-distribuidora-secundaria",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/_cLXvDBnZPM?rel=0&autoplay=1;",
      },
      {
        empresa: "lala-distribuidora-secundaria",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/0dZgdYiQi5g?rel=0&autoplay=1;",
      },
      {
        empresa: "jumex",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/8cywniGy1-U?rel=0&autoplay=1;",
      },
      {
        empresa: "jumex",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/QLb0YUqkk-k?rel=0&autoplay=1;",
      },
      {
        empresa: "jumex",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/LYlmebSSCp8?rel=0&autoplay=1;",
      },
      {
        empresa: "palacio-de-hierro",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/JwSTapF2Kk4?rel=0&autoplay=1;",
      },
      {
        empresa: "palacio-de-hierro",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/ZN0L03EwPIk?rel=0&autoplay=1;",
      },
      {
        empresa: "bepensa",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/nRolP3InZv8?rel=0&autoplay=1;",
      },
      {
        empresa: "fabrica-de-jabon",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/BWy1Sny6BYM?rel=0&autoplay=1;",
      },
      {
        empresa: "fabrica-de-jabon",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/ZJqa0ptyO-4?rel=0&autoplay=1;",
      },
      {
        empresa: "fabrica-de-jabon",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/Kv7NExFmPno?rel=0&autoplay=1;",
      },
      {
        empresa: "hesa",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/5G9bK3f78Bk?rel=0&autoplay=1;",
      },
      {
        empresa: "hesa",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/rp_mOfZFhv8?rel=0&autoplay=1;",
      },
      {
        empresa: "hesa",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/tXIpNnql_fA?rel=0&autoplay=1;",
      },
      {
        empresa: "linde",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/o7uWovRoSWI?rel=0&autoplay=1;",
      },
      {
        empresa: "linde",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/-mWcoeulZDE?rel=0&autoplay=1;",
      },
      {
        empresa: "linde",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/8vO2s3rRpDE?rel=0&autoplay=1;",
      },
      {
        empresa: "paquetexpress",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/TQ9Wpse_NFc?rel=0&autoplay=1;",
      },
      {
        empresa: "paquetexpress",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/u1sX4MLBYcc?rel=0&autoplay=1;",
      },
      {
        empresa: "paquetexpress",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/Wg9Wc-eRS5k?rel=0&autoplay=1;",
      },
      {
        empresa: "sigma",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/WXtTy2mLWJ4?rel=0&autoplay=1;",
      },
      {
        empresa: "sigma",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/i507xf1Wqpk?rel=0&autoplay=1;",
      },
      {
        empresa: "sigma",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/CjjJYHcsHXw?rel=0&autoplay=1;",
      },
      {
        empresa: "solistica",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/FIqysBRzK2M?rel=0&autoplay=1;",
      },
      {
        empresa: "solistica",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/HLaE2JTcDjk?rel=0&autoplay=1;",
      },
      {
        empresa: "solistica",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/N2kJIuYjbTs?rel=0&autoplay=1;",
      },
      {
        empresa: "tdr",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/O4h1_ZXLX9o?rel=0&autoplay=1;",
      },
      {
        empresa: "tdr",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/HTUJmQCGM0g?rel=0&autoplay=1;",
      },
      {
        empresa: "tdr",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/bkJX1JGJtoM?rel=0&autoplay=1;",
      },
      {
        empresa: "ttt",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/KU34lp5WFGc?rel=0&autoplay=1;",
      },
      {
        empresa: "transcooler",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/BLbCbV7ZOFM?rel=0&autoplay=1;",
      },
      {
        empresa: "marva",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/0XZHOq0PunU?rel=0&autoplay=1;",
      },
      {
        empresa: "marva",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/0labJg_Vw3I?rel=0&autoplay=1;",
      },
      {
        empresa: "marva",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/Bhp_aglS11A?rel=0&autoplay=1;",
      },
      {
        empresa: "trate",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/cIW8VZSY6r8?rel=0&autoplay=1;",
      },
      {
        empresa: "trate",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/CibcJRlyMSU?rel=0&autoplay=1;",
      },
      {
        empresa: "trate",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/WlJwE4bS6y0?rel=0&autoplay=1;",
      },
      {
        empresa: "zinergia",
        lugar: "1er Lugar",
        url: "https://www.youtube.com/embed/MBwvNLWbpAI?rel=0&autoplay=1;",
      },
      {
        empresa: "zinergia",
        lugar: "2do Lugar",
        url: "https://www.youtube.com/embed/gBOUkghLtXI?rel=0&autoplay=1;",
      },
      {
        empresa: "zinergia",
        lugar: "3er Lugar",
        url: "https://www.youtube.com/embed/i6bbSH5zPv4?rel=0&autoplay=1;",
      }
    
  ];

  useEffect(() => {});

  console.log("empresa", empresa);

  let ver_videos = urls
    .filter((video) => {
      if (empresa === video.empresa) {
        return true;
      } else {
        return false;
      }
    })
    .filter((lugar, index) => {
      if (ver_premiacion === lugar.lugar) {
        return true;
      } else {
        return false;
      }
    })
    .map((item) => {
      return (
        <Col span={24}>
          <LiveEmpresa
            url={item.url}
            urlyt={null}
            urlfb={null}
            urlln={null}
            webinar={nombre_evento}
            comentarios={preguntas_ponente}
            comentario_empresa={location.pathname}
            nombre_video={item.empresa}
            lugar={item.lugar}
            datos={datos}
          />
        </Col>
      );
    });

  let ver_medallas = urls
    .filter((video) => {
      if (empresa === video.empresa) {
        return true;
      } else {
        return false;
      }
    })
    .map((item, index) => {
      switch (item.lugar) {
        case "1er Lugar":
          if (item.url != "") {
            return (
              <Col
                xs={{ span: 8 }}
                onClick={() => setVerPremiacion("1er Lugar")}
              >
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        src={img_primer_lugar}
                        className="img_flip"
                        alt="1er Lugar"
                      />
                    </div>
                    <div className="flip-card-back">
                      <img
                        src={img_primer_lugar}
                        className="img_flip"
                        alt="1er Lugar"
                      />
                    </div>
                  </div>
                </div>
                <p className="lugar_text">1ER LUGAR</p>
              </Col>
            );
          }
          break;
        case "2do Lugar":
          if (item.url != "") {
            return (
              <Col span={8} onClick={() => setVerPremiacion("2do Lugar")}>
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        src={img_segundo_lugar}
                        className="img_flip"
                        alt="2do Lugar"
                      />
                    </div>
                    <div className="flip-card-back">
                      <img
                        src={img_segundo_lugar}
                        className="img_flip"
                        alt="2do Lugar"
                      />
                    </div>
                  </div>
                </div>
                <p className="lugar_text">2DO LUGAR</p>
              </Col>
            );
          }
          break;
        case "3er Lugar":
          if (item.url != "") {
            return (
              <Col span={8} onClick={() => setVerPremiacion("3er Lugar")}>
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        src={img_tercer_lugar}
                        className="img_flip"
                        alt="3er Lugar"
                      />
                    </div>
                    <div className="flip-card-back">
                      <img
                        src={img_tercer_lugar}
                        className="img_flip"
                        alt="3er Lugar"
                      />
                    </div>
                  </div>
                </div>
                <p className="lugar_text">3ER LUGAR</p>
              </Col>
            );
          }
          break;
        default:
          return <></>;
      }
    });

  return (
    <div className="contenedor_back_diploma">
      <Row align="middle">
        
        {ver_premiacion != "" ? (
          <>
          
            <Col
              xs={{ span: 23, offset: 0 }}
              lg={{ span: 5, offset: 2 }}
              style={{ textAlign: "right", padding: "10px 30px 10px 0px" }}
              onClick={() => setVerPremiacion("")}
            >
              <span className="btn_regresar"><LeftCircleOutlined /> Regresar a ganadores</span>
            </Col>
            <Col xs={{ offset: 2, span: 6 }}>
          <img src={ver_logo} className="img_100" />
        </Col>
            {ver_salon ? (
              <Col span={24}>
                <LiveEmpresa
                  url="https://www.youtube.com/embed/a-QuweNGzrc?rel=0&autoplay=1;"
                  urlyt={null}
                  urlfb={null}
                  urlln={null}
                  webinar={nombre_evento}
                  comentarios={preguntas_ponente}
                  comentario_empresa={location.pathname}
                  datos={datos}
                />
              </Col>
            ) : (
              <>{ver_videos}</>
            )}
          </>
        ) : (
          <>
            <Col
              xs={{ span: 23, offset: 0 }}
              lg={{ span: 5, offset: 2 }}
              style={{ textAlign: "right", padding: "10px 30px 10px 0px" }}
              onClick={() => setVerPremiacion("")}
            >
              <span className="btn_regresar" onClick={onResponse}>
              <LeftCircleOutlined /> Regresar a empresas
              </span>
            </Col>
            <Col xs={{ offset: 2, span: 6 }}>
          <img src={ver_logo} className="img_100" />
        </Col>
            <Col xs={{ span: 12, offset: 6 }}>
              <Row justify="center">{ver_medallas}</Row>
            </Col>
            <Col span={22} offset={1}>
              <br />

              <Diplomas
                empresa={empresa}
                ver_primero={ver_primero}
                ver_tercerp={ver_tercerp}
                ver_segundo={ver_segundo}
                ver_premiacion={ver_premiacion}
                setVerPremiacion={setVerPremiacion}
                activar_descarga={activar_descarga}
                datos={datos}
              />

              <br />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default DemoEmpresa;
